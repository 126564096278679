import { default as detaily6v1VYgTuPaMeta } from "/app/src/pages/dispecink/adresy/[id]/detaily.vue?macro=true";
import { default as dokumentaceXQQG4IhhOFMeta } from "/app/src/pages/dispecink/adresy/[id]/dokumentace.vue?macro=true";
import { default as indexoAOlCifvdEMeta } from "/app/src/pages/dispecink/adresy/[id]/index.vue?macro=true";
import { default as lokalityFCTvhifW3PMeta } from "/app/src/pages/dispecink/adresy/[id]/lokality.vue?macro=true";
import { default as _91id_93mJXEofXIk3Meta } from "/app/src/pages/dispecink/adresy/[id].vue?macro=true";
import { default as indexpGLfrqLSEYMeta } from "/app/src/pages/dispecink/adresy/index.vue?macro=true";
import { default as adresyRkXqGxOwg4Meta } from "/app/src/pages/dispecink/adresy.vue?macro=true";
import { default as aktivniRgGOHBsyZvMeta } from "/app/src/pages/dispecink/cenik/aktivni.vue?macro=true";
import { default as indexGYgQGVkJiTMeta } from "/app/src/pages/dispecink/cenik/index.vue?macro=true";
import { default as vyrazenefnNyYZjoEiMeta } from "/app/src/pages/dispecink/cenik/vyrazene.vue?macro=true";
import { default as cenikBRlzcmyRsVMeta } from "/app/src/pages/dispecink/cenik.vue?macro=true";
import { default as indexBpudubo4lXMeta } from "/app/src/pages/dispecink/dokumentace/index.vue?macro=true";
import { default as vsechnaWVk1C9GU1eMeta } from "/app/src/pages/dispecink/dokumentace/vsechna.vue?macro=true";
import { default as vybranaf7DpYjKRVKMeta } from "/app/src/pages/dispecink/dokumentace/vybrana.vue?macro=true";
import { default as dokumentacee9VIJVlXhiMeta } from "/app/src/pages/dispecink/dokumentace.vue?macro=true";
import { default as dilywQTxBUW8lBMeta } from "/app/src/pages/dispecink/faktury/[id]/dily.vue?macro=true";
import { default as dokumentaceNjOdw81QsPMeta } from "/app/src/pages/dispecink/faktury/[id]/dokumentace.vue?macro=true";
import { default as indexsFEzW0A6mUMeta } from "/app/src/pages/dispecink/faktury/[id]/index.vue?macro=true";
import { default as vykazy8xs48wo5adMeta } from "/app/src/pages/dispecink/faktury/[id]/vykazy.vue?macro=true";
import { default as _91id_93I1LL4Y9yiHMeta } from "/app/src/pages/dispecink/faktury/[id].vue?macro=true";
import { default as indexU08V8amoVlMeta } from "/app/src/pages/dispecink/faktury/index.vue?macro=true";
import { default as k_45prenosuIsKwX39K7fMeta } from "/app/src/pages/dispecink/faktury/k-prenosu.vue?macro=true";
import { default as opravene_45k_45prenosuNE2WlEQuMRMeta } from "/app/src/pages/dispecink/faktury/opravene-k-prenosu.vue?macro=true";
import { default as opravovanecXLQIWSl4CMeta } from "/app/src/pages/dispecink/faktury/opravovane.vue?macro=true";
import { default as otevreneCgOnJHxsJvMeta } from "/app/src/pages/dispecink/faktury/otevrene.vue?macro=true";
import { default as preneseneo22ef5UDRMMeta } from "/app/src/pages/dispecink/faktury/prenesene.vue?macro=true";
import { default as prevzate_45z_45qiJoybhoYUHcMeta } from "/app/src/pages/dispecink/faktury/prevzate-z-qi.vue?macro=true";
import { default as uzavreneVJzbSOrQDZMeta } from "/app/src/pages/dispecink/faktury/uzavrene.vue?macro=true";
import { default as vsechnyynBedZosqWMeta } from "/app/src/pages/dispecink/faktury/vsechny.vue?macro=true";
import { default as zruseneJlTbtMnXZjMeta } from "/app/src/pages/dispecink/faktury/zrusene.vue?macro=true";
import { default as fakturyVxLhDEnAWGMeta } from "/app/src/pages/dispecink/faktury.vue?macro=true";
import { default as diluGduYrjrt14Meta } from "/app/src/pages/dispecink/historie/dilu.vue?macro=true";
import { default as indexOVQDbJNj0LMeta } from "/app/src/pages/dispecink/historie/index.vue?macro=true";
import { default as parametru_45_91id_93zx0o3XmdU9Meta } from "/app/src/pages/dispecink/historie/parametru-[id].vue?macro=true";
import { default as zarizeniuPMpDThFv7Meta } from "/app/src/pages/dispecink/historie/zarizeni.vue?macro=true";
import { default as historieQS0QzhlL5mMeta } from "/app/src/pages/dispecink/historie.vue?macro=true";
import { default as hlaseniI74UqnnB4BMeta } from "/app/src/pages/dispecink/hlaseni.vue?macro=true";
import { default as _91id_93BXkA6153OLMeta } from "/app/src/pages/dispecink/hlidac/[id].vue?macro=true";
import { default as indexVbq81Qn7pTMeta } from "/app/src/pages/dispecink/hlidac/index.vue?macro=true";
import { default as opozdeneon5JHRAi8HMeta } from "/app/src/pages/dispecink/hlidac/opozdene.vue?macro=true";
import { default as vlastnipvRo7oWfpsMeta } from "/app/src/pages/dispecink/hlidac/vlastni.vue?macro=true";
import { default as hlidacblrXAEgQAgMeta } from "/app/src/pages/dispecink/hlidac.vue?macro=true";
import { default as indexbUfCV9YWx9Meta } from "/app/src/pages/dispecink/index.vue?macro=true";
import { default as dilyOtLSnxuWFwMeta } from "/app/src/pages/dispecink/lokality/[id]/dily.vue?macro=true";
import { default as indexroTALuruFKMeta } from "/app/src/pages/dispecink/lokality/[id]/dokumentace/index.vue?macro=true";
import { default as vsechnaae1eK6kVwIMeta } from "/app/src/pages/dispecink/lokality/[id]/dokumentace/vsechna.vue?macro=true";
import { default as vybranaagY6M4JF2ZMeta } from "/app/src/pages/dispecink/lokality/[id]/dokumentace/vybrana.vue?macro=true";
import { default as dokumentacemBILj29dWCMeta } from "/app/src/pages/dispecink/lokality/[id]/dokumentace.vue?macro=true";
import { default as hlasenibNyuLO68kCMeta } from "/app/src/pages/dispecink/lokality/[id]/hlaseni.vue?macro=true";
import { default as indexcxclu2P8XuMeta } from "/app/src/pages/dispecink/lokality/[id]/index.vue?macro=true";
import { default as indexoOkZMAyWYXMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/index.vue?macro=true";
import { default as k_45upomincelkniYg48WPMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/k-upomince.vue?macro=true";
import { default as neprijatePtBNOKSQosMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/neprijate.vue?macro=true";
import { default as odeslaneOOJSAnH5VcMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/odeslane.vue?macro=true";
import { default as pripraveneloxOBdrR3bMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/pripravene.vue?macro=true";
import { default as realizovane1gtx6Ld6wFMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/realizovane.vue?macro=true";
import { default as rozpracovaneBO6UlN3pq6Meta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/rozpracovane.vue?macro=true";
import { default as schvalenePIwf3ODSDdMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/schvalene.vue?macro=true";
import { default as upominanestRPbHVsNdMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/upominane.vue?macro=true";
import { default as vsechny_45nabidkyFmnRGhfQfVMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/vsechny-nabidky.vue?macro=true";
import { default as vsechny_45polozkyeqqNeqSXkPMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/vsechny-polozky.vue?macro=true";
import { default as vzory9K6fqPza5WMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky/vzory.vue?macro=true";
import { default as nabidkyeTnojFtfLiMeta } from "/app/src/pages/dispecink/lokality/[id]/nabidky.vue?macro=true";
import { default as aktivni6IrsWtLxOBMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/aktivni.vue?macro=true";
import { default as indexyEOVw520ZdMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/index.vue?macro=true";
import { default as k_45planub1EKlDfQBGMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-planu.vue?macro=true";
import { default as k_45revizi_45protipozarnich_45klapek2UFdh9GxhrMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-protipozarnich-klapek.vue?macro=true";
import { default as k_45revizi_45tns_45provoznimlEkw3c1WEMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-tns-provozni.vue?macro=true";
import { default as k_45revizi_45tns_45tlakova_45zkouskaRCNoh9ylzwMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-tns-tlakova-zkouska.vue?macro=true";
import { default as k_45revizi_45tns_45vnitrnix5kvZ7cpnqMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-tns-vnitrni.vue?macro=true";
import { default as k_45revizi_45unikuxGA8gR0f3pMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-uniku.vue?macro=true";
import { default as k_45vymene_45ventilu09XLVtcW4VMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/k-vymene-ventilu.vue?macro=true";
import { default as vsechnyUv5UCEvb2NMeta } from "/app/src/pages/dispecink/lokality/[id]/plany/vsechny.vue?macro=true";
import { default as planyYTfWnqEbvnMeta } from "/app/src/pages/dispecink/lokality/[id]/plany.vue?macro=true";
import { default as protokolXrp93P3bICMeta } from "/app/src/pages/dispecink/lokality/[id]/protokol.vue?macro=true";
import { default as bez_45planuK7CWbnXhgvMeta } from "/app/src/pages/dispecink/lokality/[id]/sledovany-material/bez-planu.vue?macro=true";
import { default as index3fMEKnWbfBMeta } from "/app/src/pages/dispecink/lokality/[id]/sledovany-material/index.vue?macro=true";
import { default as s_45mnozstvim7qDp22mlNtMeta } from "/app/src/pages/dispecink/lokality/[id]/sledovany-material/s-mnozstvim.vue?macro=true";
import { default as s_45planemKE4i3F5fGpMeta } from "/app/src/pages/dispecink/lokality/[id]/sledovany-material/s-planem.vue?macro=true";
import { default as vsechenGNOwGNlvBeMeta } from "/app/src/pages/dispecink/lokality/[id]/sledovany-material/vsechen.vue?macro=true";
import { default as sledovany_45material9mtp8BY6l6Meta } from "/app/src/pages/dispecink/lokality/[id]/sledovany-material.vue?macro=true";
import { default as aktivnibePCEseRFHMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti/aktivni.vue?macro=true";
import { default as indexZKFKTtcwLvMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti/index.vue?macro=true";
import { default as provedeneaTIb8KIxiXMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti/provedene.vue?macro=true";
import { default as uzavreneQMJpzwvxXcMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti/uzavrene.vue?macro=true";
import { default as z_45puvodnich_45lokalitH0D0SIXYGMMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti/z-puvodnich-lokalit.vue?macro=true";
import { default as zruseneYdSZcWfbfiMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti/zrusene.vue?macro=true";
import { default as udalostiq4Ds4tTAUCMeta } from "/app/src/pages/dispecink/lokality/[id]/udalosti.vue?macro=true";
import { default as index9TaFTTulDrMeta } from "/app/src/pages/dispecink/lokality/[id]/vykazy/index.vue?macro=true";
import { default as k_45fakturaciA1O4sPk26nMeta } from "/app/src/pages/dispecink/lokality/[id]/vykazy/k-fakturaci.vue?macro=true";
import { default as vsechnyyEhs4bQ4vRMeta } from "/app/src/pages/dispecink/lokality/[id]/vykazy/vsechny.vue?macro=true";
import { default as vykazyDcO2tHJYwBMeta } from "/app/src/pages/dispecink/lokality/[id]/vykazy.vue?macro=true";
import { default as vystupyJTedzELBz8Meta } from "/app/src/pages/dispecink/lokality/[id]/vystupy.vue?macro=true";
import { default as demontovanehjDAylqbqZMeta } from "/app/src/pages/dispecink/lokality/[id]/zarizeni/demontovane.vue?macro=true";
import { default as indexAY362MbnI6Meta } from "/app/src/pages/dispecink/lokality/[id]/zarizeni/index.vue?macro=true";
import { default as instalovaneXc6wr0CeXCMeta } from "/app/src/pages/dispecink/lokality/[id]/zarizeni/instalovane.vue?macro=true";
import { default as zarizenisxGQkWrMEXMeta } from "/app/src/pages/dispecink/lokality/[id]/zarizeni.vue?macro=true";
import { default as _91id_93DvM215iFdwMeta } from "/app/src/pages/dispecink/lokality/[id].vue?macro=true";
import { default as aktivnienheCxtiF8Meta } from "/app/src/pages/dispecink/lokality/aktivni.vue?macro=true";
import { default as alarmyzIBgPCOjzWMeta } from "/app/src/pages/dispecink/lokality/alarmy.vue?macro=true";
import { default as indexO3CQqE34uQMeta } from "/app/src/pages/dispecink/lokality/index.vue?macro=true";
import { default as narocnosti12IAfFSvDsMeta } from "/app/src/pages/dispecink/lokality/narocnosti.vue?macro=true";
import { default as zrusene2QkRQ9hozYMeta } from "/app/src/pages/dispecink/lokality/zrusene.vue?macro=true";
import { default as lokalityqRWbmyc9hLMeta } from "/app/src/pages/dispecink/lokality.vue?macro=true";
import { default as dokumentaceTvyIJew092Meta } from "/app/src/pages/dispecink/nabidky/[id]/dokumentace.vue?macro=true";
import { default as indextyNlqx7KulMeta } from "/app/src/pages/dispecink/nabidky/[id]/index.vue?macro=true";
import { default as polozkyeNT4wieIfSMeta } from "/app/src/pages/dispecink/nabidky/[id]/polozky.vue?macro=true";
import { default as _91id_93ydegqUOXFHMeta } from "/app/src/pages/dispecink/nabidky/[id].vue?macro=true";
import { default as indexGVCRWijQpsMeta } from "/app/src/pages/dispecink/nabidky/index.vue?macro=true";
import { default as k_45upomince0tVwrwmicYMeta } from "/app/src/pages/dispecink/nabidky/k-upomince.vue?macro=true";
import { default as neprijatehITIHfg51SMeta } from "/app/src/pages/dispecink/nabidky/neprijate.vue?macro=true";
import { default as _91id_93zWYHVxyT36Meta } from "/app/src/pages/dispecink/nabidky/nova/[id].vue?macro=true";
import { default as odeslanev0svMzWvcnMeta } from "/app/src/pages/dispecink/nabidky/odeslane.vue?macro=true";
import { default as pripraveneVuseUq5cCOMeta } from "/app/src/pages/dispecink/nabidky/pripravene.vue?macro=true";
import { default as realizovanebLnPdkyJebMeta } from "/app/src/pages/dispecink/nabidky/realizovane.vue?macro=true";
import { default as rozpracovaneCtUi6WgL9DMeta } from "/app/src/pages/dispecink/nabidky/rozpracovane.vue?macro=true";
import { default as schvalene6Xygen96hFMeta } from "/app/src/pages/dispecink/nabidky/schvalene.vue?macro=true";
import { default as upominanexiQE0kocdDMeta } from "/app/src/pages/dispecink/nabidky/upominane.vue?macro=true";
import { default as vsechny_45nabidkyFnAdgiMc3wMeta } from "/app/src/pages/dispecink/nabidky/vsechny-nabidky.vue?macro=true";
import { default as vsechny_45polozkybF7yaztpIIMeta } from "/app/src/pages/dispecink/nabidky/vsechny-polozky.vue?macro=true";
import { default as vzorySF7MHZIABdMeta } from "/app/src/pages/dispecink/nabidky/vzory.vue?macro=true";
import { default as nabidkyGpLbRzXGWHMeta } from "/app/src/pages/dispecink/nabidky.vue?macro=true";
import { default as indexlUg5kW6YLzMeta } from "/app/src/pages/dispecink/nahradni-reseni/index.vue?macro=true";
import { default as instalovaneakRG3sO6zyMeta } from "/app/src/pages/dispecink/nahradni-reseni/instalovane.vue?macro=true";
import { default as odstranene1CjZyaPLpeMeta } from "/app/src/pages/dispecink/nahradni-reseni/odstranene.vue?macro=true";
import { default as nahradni_45reseniaN6N6TMzqpMeta } from "/app/src/pages/dispecink/nahradni-reseni.vue?macro=true";
import { default as index28AiWTmmxVMeta } from "/app/src/pages/dispecink/nemo/index.vue?macro=true";
import { default as _91id_93ofuU5DtK4pMeta } from "/app/src/pages/dispecink/nemo/soubory/[id].vue?macro=true";
import { default as index8aakrMwmP1Meta } from "/app/src/pages/dispecink/nemo/soubory/index.vue?macro=true";
import { default as souboryznyt1BO4ZmMeta } from "/app/src/pages/dispecink/nemo/soubory.vue?macro=true";
import { default as _91id_93xOjLWPN8kiMeta } from "/app/src/pages/dispecink/nemo/udaje/[id].vue?macro=true";
import { default as indexLRtqWLnodPMeta } from "/app/src/pages/dispecink/nemo/udaje/index.vue?macro=true";
import { default as udajewpry5fBMYKMeta } from "/app/src/pages/dispecink/nemo/udaje.vue?macro=true";
import { default as nemoS8BCSngZYsMeta } from "/app/src/pages/dispecink/nemo.vue?macro=true";
import { default as index0cxsjR6QvxMeta } from "/app/src/pages/dispecink/obchodni-pripady/[id]/index.vue?macro=true";
import { default as _91id_93iyfml1x0PAMeta } from "/app/src/pages/dispecink/obchodni-pripady/[id].vue?macro=true";
import { default as indexq9uyuhBHieMeta } from "/app/src/pages/dispecink/obchodni-pripady/index.vue?macro=true";
import { default as obchodni_45pripady6d6JXvCVsnMeta } from "/app/src/pages/dispecink/obchodni-pripady.vue?macro=true";
import { default as cenikJERRqrfxksMeta } from "/app/src/pages/dispecink/partneri/[id]/cenik.vue?macro=true";
import { default as indexXcX0oP7GizMeta } from "/app/src/pages/dispecink/partneri/[id]/index.vue?macro=true";
import { default as kontaktyfp2PurQqh2Meta } from "/app/src/pages/dispecink/partneri/[id]/kontakty.vue?macro=true";
import { default as lokalitydyCFgYm9nTMeta } from "/app/src/pages/dispecink/partneri/[id]/lokality.vue?macro=true";
import { default as nabidkyl4X0ZKco0uMeta } from "/app/src/pages/dispecink/partneri/[id]/nabidky.vue?macro=true";
import { default as oblastijd7DYqLegzMeta } from "/app/src/pages/dispecink/partneri/[id]/oblasti.vue?macro=true";
import { default as udalostiCdk7gYzBmjMeta } from "/app/src/pages/dispecink/partneri/[id]/udalosti.vue?macro=true";
import { default as vystupyZ50cBVq95rMeta } from "/app/src/pages/dispecink/partneri/[id]/vystupy.vue?macro=true";
import { default as _91id_93OWpTJhPpnCMeta } from "/app/src/pages/dispecink/partneri/[id].vue?macro=true";
import { default as indexoCc7PSQMSLMeta } from "/app/src/pages/dispecink/partneri/index.vue?macro=true";
import { default as s_45lokalitamitVu4tXjtHlMeta } from "/app/src/pages/dispecink/partneri/s-lokalitami.vue?macro=true";
import { default as vsichni7flITmpbhvMeta } from "/app/src/pages/dispecink/partneri/vsichni.vue?macro=true";
import { default as partneri2KHglw0sVoMeta } from "/app/src/pages/dispecink/partneri.vue?macro=true";
import { default as aktivnieE0VX0aruzMeta } from "/app/src/pages/dispecink/plany/aktivni.vue?macro=true";
import { default as indexdaFbYR9GMwMeta } from "/app/src/pages/dispecink/plany/index.vue?macro=true";
import { default as k_45planuNC0hdar2XdMeta } from "/app/src/pages/dispecink/plany/k-planu.vue?macro=true";
import { default as k_45revizi_45protipozarnich_45klapekEn9t32DDqtMeta } from "/app/src/pages/dispecink/plany/k-revizi-protipozarnich-klapek.vue?macro=true";
import { default as k_45revizi_45tns_45provozniYjCblOOh8IMeta } from "/app/src/pages/dispecink/plany/k-revizi-tns-provozni.vue?macro=true";
import { default as k_45revizi_45tns_45tlakova_45zkouskaXrEmtOs3IiMeta } from "/app/src/pages/dispecink/plany/k-revizi-tns-tlakova-zkouska.vue?macro=true";
import { default as k_45revizi_45tns_45vnitrni9jwXOv9xKTMeta } from "/app/src/pages/dispecink/plany/k-revizi-tns-vnitrni.vue?macro=true";
import { default as k_45revizi_45unikuTJ9LNgxSkOMeta } from "/app/src/pages/dispecink/plany/k-revizi-uniku.vue?macro=true";
import { default as k_45revizi_45ventiluYRZL1aSFWJMeta } from "/app/src/pages/dispecink/plany/k-revizi-ventilu.vue?macro=true";
import { default as vsechnyn10P7o1LEbMeta } from "/app/src/pages/dispecink/plany/vsechny.vue?macro=true";
import { default as plany7RWnSupQuIMeta } from "/app/src/pages/dispecink/plany.vue?macro=true";
import { default as indexBZ5PPmakF9Meta } from "/app/src/pages/dispecink/pozadavky/[id]/index.vue?macro=true";
import { default as parametry8KapLJXNtwMeta } from "/app/src/pages/dispecink/pozadavky/[id]/parametry.vue?macro=true";
import { default as vykazydvAGieBOmaMeta } from "/app/src/pages/dispecink/pozadavky/[id]/vykazy.vue?macro=true";
import { default as _91id_93SpNyxtaAtRMeta } from "/app/src/pages/dispecink/pozadavky/[id].vue?macro=true";
import { default as budouciM7KRKDRQKMMeta } from "/app/src/pages/dispecink/pozadavky/budouci.vue?macro=true";
import { default as index7WP3fcv1yTMeta } from "/app/src/pages/dispecink/pozadavky/index.vue?macro=true";
import { default as _91id_93BPxKVbKrcgMeta } from "/app/src/pages/dispecink/pozadavky/parameter/[id].vue?macro=true";
import { default as platneQ6rncSDJQrMeta } from "/app/src/pages/dispecink/pozadavky/platne.vue?macro=true";
import { default as ukonceneLYYYRba7DFMeta } from "/app/src/pages/dispecink/pozadavky/ukoncene.vue?macro=true";
import { default as vsechnykcvQ0ZS2WHMeta } from "/app/src/pages/dispecink/pozadavky/vsechny.vue?macro=true";
import { default as pozadavky25zvKc17pOMeta } from "/app/src/pages/dispecink/pozadavky.vue?macro=true";
import { default as index5ehc52dRbpMeta } from "/app/src/pages/dispecink/poznamky/index.vue?macro=true";
import { default as poznamkyZPJLul0kUjMeta } from "/app/src/pages/dispecink/poznamky.vue?macro=true";
import { default as dilySPH4vdgPgdMeta } from "/app/src/pages/dispecink/predavaci-protokoly/[id]/dily.vue?macro=true";
import { default as dokumentacekDuxMdoXoPMeta } from "/app/src/pages/dispecink/predavaci-protokoly/[id]/dokumentace.vue?macro=true";
import { default as index2Lbk4RdGdLMeta } from "/app/src/pages/dispecink/predavaci-protokoly/[id]/index.vue?macro=true";
import { default as _91id_93J6EwVDizrVMeta } from "/app/src/pages/dispecink/predavaci-protokoly/[id].vue?macro=true";
import { default as indexkX9J18Wlw5Meta } from "/app/src/pages/dispecink/predavaci-protokoly/index.vue?macro=true";
import { default as odeslane1mrxLakD2bMeta } from "/app/src/pages/dispecink/predavaci-protokoly/odeslane.vue?macro=true";
import { default as rozpracovaneEkjdjjkn6WMeta } from "/app/src/pages/dispecink/predavaci-protokoly/rozpracovane.vue?macro=true";
import { default as vsechnyaXaxTYRAjDMeta } from "/app/src/pages/dispecink/predavaci-protokoly/vsechny.vue?macro=true";
import { default as predavaci_45protokolyESbJ2Ad4s3Meta } from "/app/src/pages/dispecink/predavaci-protokoly.vue?macro=true";
import { default as indexRbqeamhCXiMeta } from "/app/src/pages/dispecink/sestavy/index.vue?macro=true";
import { default as _91id_93BDqtHdJNLzMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti/[id].vue?macro=true";
import { default as index8iSt0QFkFFMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti/index.vue?macro=true";
import { default as s_45vykazemVQdntC0PqkMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti/s-vykazem.vue?macro=true";
import { default as se_45sledovanym_45materialemUrrIQn2EcSMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti/se-sledovanym-materialem.vue?macro=true";
import { default as vsechnyIruB0GRu4EMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti/vsechny.vue?macro=true";
import { default as z_45nabidek6capfUBD8ZMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti/z-nabidek.vue?macro=true";
import { default as material_45udalostiCuov1NMWWJMeta } from "/app/src/pages/dispecink/sestavy/material-udalosti.vue?macro=true";
import { default as bez_45planuWn7kCJNRSCMeta } from "/app/src/pages/dispecink/sestavy/sledovany-material/bez-planu.vue?macro=true";
import { default as indexw2pmlXtjmwMeta } from "/app/src/pages/dispecink/sestavy/sledovany-material/index.vue?macro=true";
import { default as s_45mnozstvimiZDRG0F5YVMeta } from "/app/src/pages/dispecink/sestavy/sledovany-material/s-mnozstvim.vue?macro=true";
import { default as s_45planemG3D3fqhOk0Meta } from "/app/src/pages/dispecink/sestavy/sledovany-material/s-planem.vue?macro=true";
import { default as vsechenW9y4aUUwf7Meta } from "/app/src/pages/dispecink/sestavy/sledovany-material/vsechen.vue?macro=true";
import { default as sledovany_45materialIXK7ZWBOHCMeta } from "/app/src/pages/dispecink/sestavy/sledovany-material.vue?macro=true";
import { default as sestavyMLOuHbCynIMeta } from "/app/src/pages/dispecink/sestavy.vue?macro=true";
import { default as _91id_93EZ04xzgxicMeta } from "/app/src/pages/dispecink/stavy-skladu/[id].vue?macro=true";
import { default as indexYHxf6Fsky9Meta } from "/app/src/pages/dispecink/stavy-skladu/index.vue?macro=true";
import { default as skladoveoWF0V1HKD9Meta } from "/app/src/pages/dispecink/stavy-skladu/skladove.vue?macro=true";
import { default as vsechnyfiP67Sw97CMeta } from "/app/src/pages/dispecink/stavy-skladu/vsechny.vue?macro=true";
import { default as stavy_45skladu4IiUEkoloXMeta } from "/app/src/pages/dispecink/stavy-skladu.vue?macro=true";
import { default as cenikUT2Ie4A5giMeta } from "/app/src/pages/dispecink/strediska/[id]/cenik.vue?macro=true";
import { default as dilyR96eHEOycfMeta } from "/app/src/pages/dispecink/strediska/[id]/dily.vue?macro=true";
import { default as dokumentace8iH1avsZh2Meta } from "/app/src/pages/dispecink/strediska/[id]/dokumentace.vue?macro=true";
import { default as hlaseniTDQasWrxC8Meta } from "/app/src/pages/dispecink/strediska/[id]/hlaseni.vue?macro=true";
import { default as index4nhhh79cGpMeta } from "/app/src/pages/dispecink/strediska/[id]/index.vue?macro=true";
import { default as lokalitygZnoT47iErMeta } from "/app/src/pages/dispecink/strediska/[id]/lokality.vue?macro=true";
import { default as aktivniZY1Z3uEfV6Meta } from "/app/src/pages/dispecink/strediska/[id]/technici/aktivni.vue?macro=true";
import { default as indexcVkale5EhzMeta } from "/app/src/pages/dispecink/strediska/[id]/technici/index.vue?macro=true";
import { default as neaktivni5a7qaYB5XkMeta } from "/app/src/pages/dispecink/strediska/[id]/technici/neaktivni.vue?macro=true";
import { default as techniciQWIStPRmaSMeta } from "/app/src/pages/dispecink/strediska/[id]/technici.vue?macro=true";
import { default as aktivniAl8j4dlRBpMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/aktivni.vue?macro=true";
import { default as index4xAgQj4X9VMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/index.vue?macro=true";
import { default as noveIWzJ4WMES5Meta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/nove.vue?macro=true";
import { default as potvrzeni_45vstupuCGadOzhOUfMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/potvrzeni-vstupu.vue?macro=true";
import { default as uzavrenecTBMjeIz9dMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/uzavrene.vue?macro=true";
import { default as vsechny7oXdo2NOhMMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/vsechny.vue?macro=true";
import { default as zadosti_45o_45vstupt6bR6CuczkMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti/zadosti-o-vstup.vue?macro=true";
import { default as udalosti0J3ET2XV3xMeta } from "/app/src/pages/dispecink/strediska/[id]/udalosti.vue?macro=true";
import { default as vykazya710PP76RZMeta } from "/app/src/pages/dispecink/strediska/[id]/vykazy.vue?macro=true";
import { default as _91id_933guqagWEhzMeta } from "/app/src/pages/dispecink/strediska/[id].vue?macro=true";
import { default as aktivni6EeiSGa6RiMeta } from "/app/src/pages/dispecink/strediska/aktivni.vue?macro=true";
import { default as indexSXmF0hcmqjMeta } from "/app/src/pages/dispecink/strediska/index.vue?macro=true";
import { default as neaktivniDTPn05AlVWMeta } from "/app/src/pages/dispecink/strediska/neaktivni.vue?macro=true";
import { default as strediskaEhwpF28XXSMeta } from "/app/src/pages/dispecink/strediska.vue?macro=true";
import { default as demontovaneALIHnUGA3CMeta } from "/app/src/pages/dispecink/typy-dilu/[id]/demontovane.vue?macro=true";
import { default as index9qZgZ9yGJtMeta } from "/app/src/pages/dispecink/typy-dilu/[id]/index.vue?macro=true";
import { default as instalovaneI2UpP9iBshMeta } from "/app/src/pages/dispecink/typy-dilu/[id]/instalovane.vue?macro=true";
import { default as nahradni_45dilyp09bKHYW8IMeta } from "/app/src/pages/dispecink/typy-dilu/[id]/nahradni-dily.vue?macro=true";
import { default as _91id_9386x2iSo7LtMeta } from "/app/src/pages/dispecink/typy-dilu/[id].vue?macro=true";
import { default as indexcUFmUl4iTJMeta } from "/app/src/pages/dispecink/typy-dilu/index.vue?macro=true";
import { default as pripraveneJGkZGszCKWMeta } from "/app/src/pages/dispecink/typy-dilu/pripravene.vue?macro=true";
import { default as vyrazeneZHW5DqpjgZMeta } from "/app/src/pages/dispecink/typy-dilu/vyrazene.vue?macro=true";
import { default as typy_45diluuqthPraSYFMeta } from "/app/src/pages/dispecink/typy-dilu.vue?macro=true";
import { default as dokumentaceLBOXJfbDtQMeta } from "/app/src/pages/dispecink/udalosti/[id]/dokumentace.vue?macro=true";
import { default as indexcMad59qe2kMeta } from "/app/src/pages/dispecink/udalosti/[id]/hlaseni/index.vue?macro=true";
import { default as k_45dilum_45udalosti9ONHScEKyHMeta } from "/app/src/pages/dispecink/udalosti/[id]/hlaseni/k-dilum-udalosti.vue?macro=true";
import { default as vsechnapyuMZSexIxMeta } from "/app/src/pages/dispecink/udalosti/[id]/hlaseni/vsechna.vue?macro=true";
import { default as hlasenisArMb12LBLMeta } from "/app/src/pages/dispecink/udalosti/[id]/hlaseni.vue?macro=true";
import { default as indexKSqr7BpCy4Meta } from "/app/src/pages/dispecink/udalosti/[id]/index.vue?macro=true";
import { default as materialeMw71SyDTtMeta } from "/app/src/pages/dispecink/udalosti/[id]/material.vue?macro=true";
import { default as nabidkyn50hHZhxYeMeta } from "/app/src/pages/dispecink/udalosti/[id]/nabidky.vue?macro=true";
import { default as nezpracovaneFH2YlCJJFhMeta } from "/app/src/pages/dispecink/udalosti/[id]/nemo/nezpracovane.vue?macro=true";
import { default as vse9T1f2hMly2Meta } from "/app/src/pages/dispecink/udalosti/[id]/nemo/vse.vue?macro=true";
import { default as nemoSxBaJqq1QlMeta } from "/app/src/pages/dispecink/udalosti/[id]/nemo.vue?macro=true";
import { default as sledovany_45materialiwrwLEKLiZMeta } from "/app/src/pages/dispecink/udalosti/[id]/sledovany-material.vue?macro=true";
import { default as ukolyEpbaMERqG0Meta } from "/app/src/pages/dispecink/udalosti/[id]/ukoly.vue?macro=true";
import { default as _91id_93rXhB6PaHwAMeta } from "/app/src/pages/dispecink/udalosti/[id].vue?macro=true";
import { default as aktivniMG3o4CoeI7Meta } from "/app/src/pages/dispecink/udalosti/aktivni.vue?macro=true";
import { default as fakturovatbCREmZ3D2NMeta } from "/app/src/pages/dispecink/udalosti/fakturovat.vue?macro=true";
import { default as indexWYnm2AIe95Meta } from "/app/src/pages/dispecink/udalosti/index.vue?macro=true";
import { default as uzavreneFCUaADLIVNMeta } from "/app/src/pages/dispecink/udalosti/uzavrene.vue?macro=true";
import { default as vseNfxPIaTllGMeta } from "/app/src/pages/dispecink/udalosti/vse.vue?macro=true";
import { default as zpracovatMuvQP3foYqMeta } from "/app/src/pages/dispecink/udalosti/zpracovat.vue?macro=true";
import { default as zruseneGZMPcMF3aNMeta } from "/app/src/pages/dispecink/udalosti/zrusene.vue?macro=true";
import { default as udalostiYSxbIhn7g3Meta } from "/app/src/pages/dispecink/udalosti.vue?macro=true";
import { default as alarmyOQwGrkZi7tMeta } from "/app/src/pages/dispecink/ukoly/alarmy.vue?macro=true";
import { default as indexgpCQLzddyTMeta } from "/app/src/pages/dispecink/ukoly/index.vue?macro=true";
import { default as pripraveneBjPAaD7IPqMeta } from "/app/src/pages/dispecink/ukoly/pripravene.vue?macro=true";
import { default as vsechnyuxkJdZ2X09Meta } from "/app/src/pages/dispecink/ukoly/vsechny.vue?macro=true";
import { default as ukolyQfrMIhx6RpMeta } from "/app/src/pages/dispecink/ukoly.vue?macro=true";
import { default as aplikacelZhInbZRhdMeta } from "/app/src/pages/dispecink/uzivatele/aplikace.vue?macro=true";
import { default as indexc0GqvCU0KJMeta } from "/app/src/pages/dispecink/uzivatele/index.vue?macro=true";
import { default as skupiny9S17M7Azk1Meta } from "/app/src/pages/dispecink/uzivatele/skupiny.vue?macro=true";
import { default as aktivni3rjj5qQWixMeta } from "/app/src/pages/dispecink/uzivatele/uzivatele/aktivni.vue?macro=true";
import { default as indexk715s6DlFIMeta } from "/app/src/pages/dispecink/uzivatele/uzivatele/index.vue?macro=true";
import { default as neaktivnia6NMAiAiQQMeta } from "/app/src/pages/dispecink/uzivatele/uzivatele/neaktivni.vue?macro=true";
import { default as vsektwppiAkJQMeta } from "/app/src/pages/dispecink/uzivatele/uzivatele/vse.vue?macro=true";
import { default as uzivateleMwU6RsUR0TMeta } from "/app/src/pages/dispecink/uzivatele/uzivatele.vue?macro=true";
import { default as uzivateleDSmjWiNew0Meta } from "/app/src/pages/dispecink/uzivatele.vue?macro=true";
import { default as index0pPVYnaOLiMeta } from "/app/src/pages/dispecink/vykazy/index.vue?macro=true";
import { default as k_45fakturaciX9rhyI1712Meta } from "/app/src/pages/dispecink/vykazy/k-fakturaci.vue?macro=true";
import { default as vsechnyUGvI1lPGtYMeta } from "/app/src/pages/dispecink/vykazy/vsechny.vue?macro=true";
import { default as vykazy5Lth0EAEaPMeta } from "/app/src/pages/dispecink/vykazy.vue?macro=true";
import { default as _91id_93RbnbJVfEfeMeta } from "/app/src/pages/dispecink/vzkazy/[id].vue?macro=true";
import { default as indexioBVqFaUWhMeta } from "/app/src/pages/dispecink/vzkazy/index.vue?macro=true";
import { default as k_45ukonceni8ePyzXmDn6Meta } from "/app/src/pages/dispecink/vzkazy/k-ukonceni.vue?macro=true";
import { default as pripravene2gx2w3OTatMeta } from "/app/src/pages/dispecink/vzkazy/pripravene.vue?macro=true";
import { default as pro_45mepEqb7aeeJtMeta } from "/app/src/pages/dispecink/vzkazy/pro-me.vue?macro=true";
import { default as ukoncene0R2ApUDe1HMeta } from "/app/src/pages/dispecink/vzkazy/ukoncene.vue?macro=true";
import { default as vlastnieD1nruy3AgMeta } from "/app/src/pages/dispecink/vzkazy/vlastni.vue?macro=true";
import { default as vsechnykfke1pcMmdMeta } from "/app/src/pages/dispecink/vzkazy/vsechny.vue?macro=true";
import { default as zobrazeneqbMSzwiYnYMeta } from "/app/src/pages/dispecink/vzkazy/zobrazene.vue?macro=true";
import { default as vzkazyKPYhk8U18wMeta } from "/app/src/pages/dispecink/vzkazy.vue?macro=true";
import { default as _91id_93xU3DUHZZ1jMeta } from "/app/src/pages/dispecink/vzory-textu/[id].vue?macro=true";
import { default as indexvFiUntSD1PMeta } from "/app/src/pages/dispecink/vzory-textu/index.vue?macro=true";
import { default as vzory_45textu73em1AHXnaMeta } from "/app/src/pages/dispecink/vzory-textu.vue?macro=true";
import { default as zastupyNsOMrNiuMwMeta } from "/app/src/pages/dispecink/zastupy.vue?macro=true";
import { default as dispecinks1XlUzGFOMMeta } from "/app/src/pages/dispecink.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as indexZ1C5ZYjCWBMeta } from "/app/src/pages/mapa/index.vue?macro=true";
import { default as lokalityH84hmzvUP6Meta } from "/app/src/pages/mapa/lokality.vue?macro=true";
import { default as udalostiw4LfvfzGNMMeta } from "/app/src/pages/mapa/udalosti.vue?macro=true";
import { default as mapawcAirIz6RzMeta } from "/app/src/pages/mapa.vue?macro=true";
import { default as indexPTOP8vOjEzMeta } from "/app/src/pages/obchod/index.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as indexrrXYqk3dLfMeta } from "/app/src/pages/servis/absence/index.vue?macro=true";
import { default as index6BNEOFomwVMeta } from "/app/src/pages/servis/absence/techniku/index.vue?macro=true";
import { default as prehledWbHmWhHBHbMeta } from "/app/src/pages/servis/absence/techniku/prehled.vue?macro=true";
import { default as seznam0QG2ZiRayhMeta } from "/app/src/pages/servis/absence/techniku/seznam.vue?macro=true";
import { default as technikulovzHZERTTMeta } from "/app/src/pages/servis/absence/techniku.vue?macro=true";
import { default as indexWVHrPi9BOrMeta } from "/app/src/pages/servis/absence/vozidel/index.vue?macro=true";
import { default as prehledRkWTHeo3OwMeta } from "/app/src/pages/servis/absence/vozidel/prehled.vue?macro=true";
import { default as seznam2Z6JVIwxJFMeta } from "/app/src/pages/servis/absence/vozidel/seznam.vue?macro=true";
import { default as vozidelD4a8HAm5D5Meta } from "/app/src/pages/servis/absence/vozidel.vue?macro=true";
import { default as absenceDX9fC6aCJlMeta } from "/app/src/pages/servis/absence.vue?macro=true";
import { default as aktivnierVoX67nFhMeta } from "/app/src/pages/servis/akce/aktivni.vue?macro=true";
import { default as indexLW0WG2yb47Meta } from "/app/src/pages/servis/akce/index.vue?macro=true";
import { default as neaktivnixIVCozhJX0Meta } from "/app/src/pages/servis/akce/neaktivni.vue?macro=true";
import { default as nenaplanovanepSTwqjMzstMeta } from "/app/src/pages/servis/akce/nenaplanovane.vue?macro=true";
import { default as provedeneINMjoVFsxNMeta } from "/app/src/pages/servis/akce/provedene.vue?macro=true";
import { default as rozplanovaneyyNPE1LvKhMeta } from "/app/src/pages/servis/akce/rozplanovane.vue?macro=true";
import { default as vsechnyHLLfB23uGxMeta } from "/app/src/pages/servis/akce/vsechny.vue?macro=true";
import { default as zrusene03cqaq5uuyMeta } from "/app/src/pages/servis/akce/zrusene.vue?macro=true";
import { default as akcegihWnybZKuMeta } from "/app/src/pages/servis/akce.vue?macro=true";
import { default as indexy1xTRMDP2cMeta } from "/app/src/pages/servis/chladiva/index.vue?macro=true";
import { default as novaev0njr9hUjMeta } from "/app/src/pages/servis/chladiva/nova.vue?macro=true";
import { default as odsataixFkOQdEhoMeta } from "/app/src/pages/servis/chladiva/odsata.vue?macro=true";
import { default as stavjlv5HkRv06Meta } from "/app/src/pages/servis/chladiva/stav.vue?macro=true";
import { default as chladiva1NIwLTX8U9Meta } from "/app/src/pages/servis/chladiva.vue?macro=true";
import { default as indexxo9Yy89QBqMeta } from "/app/src/pages/servis/ciselniky/index.vue?macro=true";
import { default as strediskaXnscYd4WhNMeta } from "/app/src/pages/servis/ciselniky/strediska.vue?macro=true";
import { default as techniciYUxeDNx95VMeta } from "/app/src/pages/servis/ciselniky/technici.vue?macro=true";
import { default as absenceyCUgpFPtcrMeta } from "/app/src/pages/servis/ciselniky/vozidla/[id]/absence.vue?macro=true";
import { default as vyjezdyXTuxGHCDYBMeta } from "/app/src/pages/servis/ciselniky/vozidla/[id]/vyjezdy.vue?macro=true";
import { default as _91id_93Apjgmo0swiMeta } from "/app/src/pages/servis/ciselniky/vozidla/[id].vue?macro=true";
import { default as indexFfbO4fn5lKMeta } from "/app/src/pages/servis/ciselniky/vozidla/index.vue?macro=true";
import { default as ciselnikyCFtcrXpPQEMeta } from "/app/src/pages/servis/ciselniky.vue?macro=true";
import { default as den0HX2HGJnMnMeta } from "/app/src/pages/servis/den.vue?macro=true";
import { default as dokumentace7fmpPKJiyrMeta } from "/app/src/pages/servis/dokumentace.vue?macro=true";
import { default as historieok7I5m8D9OMeta } from "/app/src/pages/servis/historie.vue?macro=true";
import { default as indexYuS0KgKJm5Meta } from "/app/src/pages/servis/index.vue?macro=true";
import { default as mesicwCVm8JOhsbMeta } from "/app/src/pages/servis/mesic.vue?macro=true";
import { default as prehledLhAais2StJMeta } from "/app/src/pages/servis/prehled.vue?macro=true";
import { default as sluzbyovR34K4wMnMeta } from "/app/src/pages/servis/sluzby.vue?macro=true";
import { default as techniciPxdVesBnRaMeta } from "/app/src/pages/servis/technici.vue?macro=true";
import { default as tydenMm2eAqbOWPMeta } from "/app/src/pages/servis/tyden.vue?macro=true";
import { default as vyjezdyuMF6Q4flaPMeta } from "/app/src/pages/servis/vyjezdy.vue?macro=true";
import { default as vzkazyT51o7BQm5dMeta } from "/app/src/pages/servis/vzkazy.vue?macro=true";
import { default as servisc5h08Gx8qSMeta } from "/app/src/pages/servis.vue?macro=true";
import { default as index6A3oH271ZgMeta } from "/app/src/pages/sluzba/dokumentace/index.vue?macro=true";
import { default as indexUGv5V8VltuMeta } from "/app/src/pages/sluzba/hlavni-strana/index.vue?macro=true";
import { default as indexImQhStrvbQMeta } from "/app/src/pages/sluzba/index.vue?macro=true";
import { default as sluzbaXGQvFrgoycMeta } from "/app/src/pages/sluzba.vue?macro=true";
import { default as indexlMJu7SBJj2Meta } from "/app/src/pages/sprava/index.vue?macro=true";
import { default as spravaq1GFPICjvnMeta } from "/app/src/pages/sprava.vue?macro=true";
export default [
  {
    name: dispecinks1XlUzGFOMMeta?.name ?? undefined,
    path: dispecinks1XlUzGFOMMeta?.path ?? "/dispecink",
    meta: dispecinks1XlUzGFOMMeta || {},
    alias: dispecinks1XlUzGFOMMeta?.alias || [],
    redirect: dispecinks1XlUzGFOMMeta?.redirect,
    component: () => import("/app/src/pages/dispecink.vue").then(m => m.default || m),
    children: [
  {
    name: adresyRkXqGxOwg4Meta?.name ?? undefined,
    path: adresyRkXqGxOwg4Meta?.path ?? "adresy",
    meta: adresyRkXqGxOwg4Meta || {},
    alias: adresyRkXqGxOwg4Meta?.alias || [],
    redirect: adresyRkXqGxOwg4Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93mJXEofXIk3Meta?.name ?? undefined,
    path: _91id_93mJXEofXIk3Meta?.path ?? ":id()",
    meta: _91id_93mJXEofXIk3Meta || {},
    alias: _91id_93mJXEofXIk3Meta?.alias || [],
    redirect: _91id_93mJXEofXIk3Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy/[id].vue").then(m => m.default || m),
    children: [
  {
    name: detaily6v1VYgTuPaMeta?.name ?? "dispecink-adresy-id-detaily___cs",
    path: detaily6v1VYgTuPaMeta?.path ?? "detaily",
    meta: detaily6v1VYgTuPaMeta || {},
    alias: detaily6v1VYgTuPaMeta?.alias || [],
    redirect: detaily6v1VYgTuPaMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy/[id]/detaily.vue").then(m => m.default || m)
  },
  {
    name: dokumentaceXQQG4IhhOFMeta?.name ?? "dispecink-adresy-id-dokumentace___cs",
    path: dokumentaceXQQG4IhhOFMeta?.path ?? "dokumentace",
    meta: dokumentaceXQQG4IhhOFMeta || {},
    alias: dokumentaceXQQG4IhhOFMeta?.alias || [],
    redirect: dokumentaceXQQG4IhhOFMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy/[id]/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: indexoAOlCifvdEMeta?.name ?? "dispecink-adresy-id___cs",
    path: indexoAOlCifvdEMeta?.path ?? "",
    meta: indexoAOlCifvdEMeta || {},
    alias: indexoAOlCifvdEMeta?.alias || [],
    redirect: indexoAOlCifvdEMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: lokalityFCTvhifW3PMeta?.name ?? "dispecink-adresy-id-lokality___cs",
    path: lokalityFCTvhifW3PMeta?.path ?? "lokality",
    meta: lokalityFCTvhifW3PMeta || {},
    alias: lokalityFCTvhifW3PMeta?.alias || [],
    redirect: lokalityFCTvhifW3PMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy/[id]/lokality.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpGLfrqLSEYMeta?.name ?? "dispecink-adresy___cs",
    path: indexpGLfrqLSEYMeta?.path ?? "",
    meta: indexpGLfrqLSEYMeta || {},
    alias: indexpGLfrqLSEYMeta?.alias || [],
    redirect: indexpGLfrqLSEYMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/adresy/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cenikBRlzcmyRsVMeta?.name ?? undefined,
    path: cenikBRlzcmyRsVMeta?.path ?? "cenik",
    meta: cenikBRlzcmyRsVMeta || {},
    alias: cenikBRlzcmyRsVMeta?.alias || [],
    redirect: cenikBRlzcmyRsVMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/cenik.vue").then(m => m.default || m),
    children: [
  {
    name: aktivniRgGOHBsyZvMeta?.name ?? "dispecink-cenik-aktivni___cs",
    path: aktivniRgGOHBsyZvMeta?.path ?? "aktivni",
    meta: aktivniRgGOHBsyZvMeta || {},
    alias: aktivniRgGOHBsyZvMeta?.alias || [],
    redirect: aktivniRgGOHBsyZvMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/cenik/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexGYgQGVkJiTMeta?.name ?? "dispecink-cenik___cs",
    path: indexGYgQGVkJiTMeta?.path ?? "",
    meta: indexGYgQGVkJiTMeta || {},
    alias: indexGYgQGVkJiTMeta?.alias || [],
    redirect: indexGYgQGVkJiTMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/cenik/index.vue").then(m => m.default || m)
  },
  {
    name: vyrazenefnNyYZjoEiMeta?.name ?? "dispecink-cenik-vyrazene___cs",
    path: vyrazenefnNyYZjoEiMeta?.path ?? "vyrazene",
    meta: vyrazenefnNyYZjoEiMeta || {},
    alias: vyrazenefnNyYZjoEiMeta?.alias || [],
    redirect: vyrazenefnNyYZjoEiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/cenik/vyrazene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dokumentacee9VIJVlXhiMeta?.name ?? undefined,
    path: dokumentacee9VIJVlXhiMeta?.path ?? "dokumentace",
    meta: dokumentacee9VIJVlXhiMeta || {},
    alias: dokumentacee9VIJVlXhiMeta?.alias || [],
    redirect: dokumentacee9VIJVlXhiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/dokumentace.vue").then(m => m.default || m),
    children: [
  {
    name: indexBpudubo4lXMeta?.name ?? "dispecink-dokumentace___cs",
    path: indexBpudubo4lXMeta?.path ?? "",
    meta: indexBpudubo4lXMeta || {},
    alias: indexBpudubo4lXMeta?.alias || [],
    redirect: indexBpudubo4lXMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/dokumentace/index.vue").then(m => m.default || m)
  },
  {
    name: vsechnaWVk1C9GU1eMeta?.name ?? "dispecink-dokumentace-vsechna___cs",
    path: vsechnaWVk1C9GU1eMeta?.path ?? "vsechna",
    meta: vsechnaWVk1C9GU1eMeta || {},
    alias: vsechnaWVk1C9GU1eMeta?.alias || [],
    redirect: vsechnaWVk1C9GU1eMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/dokumentace/vsechna.vue").then(m => m.default || m)
  },
  {
    name: vybranaf7DpYjKRVKMeta?.name ?? "dispecink-dokumentace-vybrana___cs",
    path: vybranaf7DpYjKRVKMeta?.path ?? "vybrana",
    meta: vybranaf7DpYjKRVKMeta || {},
    alias: vybranaf7DpYjKRVKMeta?.alias || [],
    redirect: vybranaf7DpYjKRVKMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/dokumentace/vybrana.vue").then(m => m.default || m)
  }
]
  },
  {
    name: fakturyVxLhDEnAWGMeta?.name ?? undefined,
    path: fakturyVxLhDEnAWGMeta?.path ?? "faktury",
    meta: fakturyVxLhDEnAWGMeta || {},
    alias: fakturyVxLhDEnAWGMeta?.alias || [],
    redirect: fakturyVxLhDEnAWGMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93I1LL4Y9yiHMeta?.name ?? undefined,
    path: _91id_93I1LL4Y9yiHMeta?.path ?? ":id()",
    meta: _91id_93I1LL4Y9yiHMeta || {},
    alias: _91id_93I1LL4Y9yiHMeta?.alias || [],
    redirect: _91id_93I1LL4Y9yiHMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/[id].vue").then(m => m.default || m),
    children: [
  {
    name: dilywQTxBUW8lBMeta?.name ?? "dispecink-faktury-id-dily___cs",
    path: dilywQTxBUW8lBMeta?.path ?? "dily",
    meta: dilywQTxBUW8lBMeta || {},
    alias: dilywQTxBUW8lBMeta?.alias || [],
    redirect: dilywQTxBUW8lBMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/[id]/dily.vue").then(m => m.default || m)
  },
  {
    name: dokumentaceNjOdw81QsPMeta?.name ?? "dispecink-faktury-id-dokumentace___cs",
    path: dokumentaceNjOdw81QsPMeta?.path ?? "dokumentace",
    meta: dokumentaceNjOdw81QsPMeta || {},
    alias: dokumentaceNjOdw81QsPMeta?.alias || [],
    redirect: dokumentaceNjOdw81QsPMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/[id]/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: indexsFEzW0A6mUMeta?.name ?? "dispecink-faktury-id___cs",
    path: indexsFEzW0A6mUMeta?.path ?? "",
    meta: indexsFEzW0A6mUMeta || {},
    alias: indexsFEzW0A6mUMeta?.alias || [],
    redirect: indexsFEzW0A6mUMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: vykazy8xs48wo5adMeta?.name ?? "dispecink-faktury-id-vykazy___cs",
    path: vykazy8xs48wo5adMeta?.path ?? "vykazy",
    meta: vykazy8xs48wo5adMeta || {},
    alias: vykazy8xs48wo5adMeta?.alias || [],
    redirect: vykazy8xs48wo5adMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/[id]/vykazy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexU08V8amoVlMeta?.name ?? "dispecink-faktury___cs",
    path: indexU08V8amoVlMeta?.path ?? "",
    meta: indexU08V8amoVlMeta || {},
    alias: indexU08V8amoVlMeta?.alias || [],
    redirect: indexU08V8amoVlMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/index.vue").then(m => m.default || m)
  },
  {
    name: k_45prenosuIsKwX39K7fMeta?.name ?? "dispecink-faktury-k-prenosu___cs",
    path: k_45prenosuIsKwX39K7fMeta?.path ?? "k-prenosu",
    meta: k_45prenosuIsKwX39K7fMeta || {},
    alias: k_45prenosuIsKwX39K7fMeta?.alias || [],
    redirect: k_45prenosuIsKwX39K7fMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/k-prenosu.vue").then(m => m.default || m)
  },
  {
    name: opravene_45k_45prenosuNE2WlEQuMRMeta?.name ?? "dispecink-faktury-opravene-k-prenosu___cs",
    path: opravene_45k_45prenosuNE2WlEQuMRMeta?.path ?? "opravene-k-prenosu",
    meta: opravene_45k_45prenosuNE2WlEQuMRMeta || {},
    alias: opravene_45k_45prenosuNE2WlEQuMRMeta?.alias || [],
    redirect: opravene_45k_45prenosuNE2WlEQuMRMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/opravene-k-prenosu.vue").then(m => m.default || m)
  },
  {
    name: opravovanecXLQIWSl4CMeta?.name ?? "dispecink-faktury-opravovane___cs",
    path: opravovanecXLQIWSl4CMeta?.path ?? "opravovane",
    meta: opravovanecXLQIWSl4CMeta || {},
    alias: opravovanecXLQIWSl4CMeta?.alias || [],
    redirect: opravovanecXLQIWSl4CMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/opravovane.vue").then(m => m.default || m)
  },
  {
    name: otevreneCgOnJHxsJvMeta?.name ?? "dispecink-faktury-otevrene___cs",
    path: otevreneCgOnJHxsJvMeta?.path ?? "otevrene",
    meta: otevreneCgOnJHxsJvMeta || {},
    alias: otevreneCgOnJHxsJvMeta?.alias || [],
    redirect: otevreneCgOnJHxsJvMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/otevrene.vue").then(m => m.default || m)
  },
  {
    name: preneseneo22ef5UDRMMeta?.name ?? "dispecink-faktury-prenesene___cs",
    path: preneseneo22ef5UDRMMeta?.path ?? "prenesene",
    meta: preneseneo22ef5UDRMMeta || {},
    alias: preneseneo22ef5UDRMMeta?.alias || [],
    redirect: preneseneo22ef5UDRMMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/prenesene.vue").then(m => m.default || m)
  },
  {
    name: prevzate_45z_45qiJoybhoYUHcMeta?.name ?? "dispecink-faktury-prevzate-z-qi___cs",
    path: prevzate_45z_45qiJoybhoYUHcMeta?.path ?? "prevzate-z-qi",
    meta: prevzate_45z_45qiJoybhoYUHcMeta || {},
    alias: prevzate_45z_45qiJoybhoYUHcMeta?.alias || [],
    redirect: prevzate_45z_45qiJoybhoYUHcMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/prevzate-z-qi.vue").then(m => m.default || m)
  },
  {
    name: uzavreneVJzbSOrQDZMeta?.name ?? "dispecink-faktury-uzavrene___cs",
    path: uzavreneVJzbSOrQDZMeta?.path ?? "uzavrene",
    meta: uzavreneVJzbSOrQDZMeta || {},
    alias: uzavreneVJzbSOrQDZMeta?.alias || [],
    redirect: uzavreneVJzbSOrQDZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/uzavrene.vue").then(m => m.default || m)
  },
  {
    name: vsechnyynBedZosqWMeta?.name ?? "dispecink-faktury-vsechny___cs",
    path: vsechnyynBedZosqWMeta?.path ?? "vsechny",
    meta: vsechnyynBedZosqWMeta || {},
    alias: vsechnyynBedZosqWMeta?.alias || [],
    redirect: vsechnyynBedZosqWMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/vsechny.vue").then(m => m.default || m)
  },
  {
    name: zruseneJlTbtMnXZjMeta?.name ?? "dispecink-faktury-zrusene___cs",
    path: zruseneJlTbtMnXZjMeta?.path ?? "zrusene",
    meta: zruseneJlTbtMnXZjMeta || {},
    alias: zruseneJlTbtMnXZjMeta?.alias || [],
    redirect: zruseneJlTbtMnXZjMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/faktury/zrusene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: historieQS0QzhlL5mMeta?.name ?? undefined,
    path: historieQS0QzhlL5mMeta?.path ?? "historie",
    meta: historieQS0QzhlL5mMeta || {},
    alias: historieQS0QzhlL5mMeta?.alias || [],
    redirect: historieQS0QzhlL5mMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/historie.vue").then(m => m.default || m),
    children: [
  {
    name: diluGduYrjrt14Meta?.name ?? "dispecink-historie-dilu___cs",
    path: diluGduYrjrt14Meta?.path ?? "dilu",
    meta: diluGduYrjrt14Meta || {},
    alias: diluGduYrjrt14Meta?.alias || [],
    redirect: diluGduYrjrt14Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/historie/dilu.vue").then(m => m.default || m)
  },
  {
    name: indexOVQDbJNj0LMeta?.name ?? "dispecink-historie___cs",
    path: indexOVQDbJNj0LMeta?.path ?? "",
    meta: indexOVQDbJNj0LMeta || {},
    alias: indexOVQDbJNj0LMeta?.alias || [],
    redirect: indexOVQDbJNj0LMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/historie/index.vue").then(m => m.default || m)
  },
  {
    name: parametru_45_91id_93zx0o3XmdU9Meta?.name ?? "dispecink-historie-parametru-id___cs",
    path: parametru_45_91id_93zx0o3XmdU9Meta?.path ?? "parametru-:id()",
    meta: parametru_45_91id_93zx0o3XmdU9Meta || {},
    alias: parametru_45_91id_93zx0o3XmdU9Meta?.alias || [],
    redirect: parametru_45_91id_93zx0o3XmdU9Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/historie/parametru-[id].vue").then(m => m.default || m)
  },
  {
    name: zarizeniuPMpDThFv7Meta?.name ?? "dispecink-historie-zarizeni___cs",
    path: zarizeniuPMpDThFv7Meta?.path ?? "zarizeni",
    meta: zarizeniuPMpDThFv7Meta || {},
    alias: zarizeniuPMpDThFv7Meta?.alias || [],
    redirect: zarizeniuPMpDThFv7Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/historie/zarizeni.vue").then(m => m.default || m)
  }
]
  },
  {
    name: hlaseniI74UqnnB4BMeta?.name ?? "dispecink-hlaseni___cs",
    path: hlaseniI74UqnnB4BMeta?.path ?? "hlaseni",
    meta: hlaseniI74UqnnB4BMeta || {},
    alias: hlaseniI74UqnnB4BMeta?.alias || [],
    redirect: hlaseniI74UqnnB4BMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/hlaseni.vue").then(m => m.default || m)
  },
  {
    name: hlidacblrXAEgQAgMeta?.name ?? undefined,
    path: hlidacblrXAEgQAgMeta?.path ?? "hlidac",
    meta: hlidacblrXAEgQAgMeta || {},
    alias: hlidacblrXAEgQAgMeta?.alias || [],
    redirect: hlidacblrXAEgQAgMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/hlidac.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93BXkA6153OLMeta?.name ?? "dispecink-hlidac-id___cs",
    path: _91id_93BXkA6153OLMeta?.path ?? ":id()",
    meta: _91id_93BXkA6153OLMeta || {},
    alias: _91id_93BXkA6153OLMeta?.alias || [],
    redirect: _91id_93BXkA6153OLMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/hlidac/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVbq81Qn7pTMeta?.name ?? "dispecink-hlidac___cs",
    path: indexVbq81Qn7pTMeta?.path ?? "",
    meta: indexVbq81Qn7pTMeta || {},
    alias: indexVbq81Qn7pTMeta?.alias || [],
    redirect: indexVbq81Qn7pTMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/hlidac/index.vue").then(m => m.default || m)
  },
  {
    name: opozdeneon5JHRAi8HMeta?.name ?? "dispecink-hlidac-opozdene___cs",
    path: opozdeneon5JHRAi8HMeta?.path ?? "opozdene",
    meta: opozdeneon5JHRAi8HMeta || {},
    alias: opozdeneon5JHRAi8HMeta?.alias || [],
    redirect: opozdeneon5JHRAi8HMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/hlidac/opozdene.vue").then(m => m.default || m)
  },
  {
    name: vlastnipvRo7oWfpsMeta?.name ?? "dispecink-hlidac-vlastni___cs",
    path: vlastnipvRo7oWfpsMeta?.path ?? "vlastni",
    meta: vlastnipvRo7oWfpsMeta || {},
    alias: vlastnipvRo7oWfpsMeta?.alias || [],
    redirect: vlastnipvRo7oWfpsMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/hlidac/vlastni.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexbUfCV9YWx9Meta?.name ?? "dispecink___cs",
    path: indexbUfCV9YWx9Meta?.path ?? "",
    meta: indexbUfCV9YWx9Meta || {},
    alias: indexbUfCV9YWx9Meta?.alias || [],
    redirect: indexbUfCV9YWx9Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/index.vue").then(m => m.default || m)
  },
  {
    name: lokalityqRWbmyc9hLMeta?.name ?? undefined,
    path: lokalityqRWbmyc9hLMeta?.path ?? "lokality",
    meta: lokalityqRWbmyc9hLMeta || {},
    alias: lokalityqRWbmyc9hLMeta?.alias || [],
    redirect: lokalityqRWbmyc9hLMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93DvM215iFdwMeta?.name ?? undefined,
    path: _91id_93DvM215iFdwMeta?.path ?? ":id()",
    meta: _91id_93DvM215iFdwMeta || {},
    alias: _91id_93DvM215iFdwMeta?.alias || [],
    redirect: _91id_93DvM215iFdwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id].vue").then(m => m.default || m),
    children: [
  {
    name: dilyOtLSnxuWFwMeta?.name ?? "dispecink-lokality-id-dily___cs",
    path: dilyOtLSnxuWFwMeta?.path ?? "dily",
    meta: dilyOtLSnxuWFwMeta || {},
    alias: dilyOtLSnxuWFwMeta?.alias || [],
    redirect: dilyOtLSnxuWFwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/dily.vue").then(m => m.default || m)
  },
  {
    name: dokumentacemBILj29dWCMeta?.name ?? undefined,
    path: dokumentacemBILj29dWCMeta?.path ?? "dokumentace",
    meta: dokumentacemBILj29dWCMeta || {},
    alias: dokumentacemBILj29dWCMeta?.alias || [],
    redirect: dokumentacemBILj29dWCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/dokumentace.vue").then(m => m.default || m),
    children: [
  {
    name: indexroTALuruFKMeta?.name ?? "dispecink-lokality-id-dokumentace___cs",
    path: indexroTALuruFKMeta?.path ?? "",
    meta: indexroTALuruFKMeta || {},
    alias: indexroTALuruFKMeta?.alias || [],
    redirect: indexroTALuruFKMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/dokumentace/index.vue").then(m => m.default || m)
  },
  {
    name: vsechnaae1eK6kVwIMeta?.name ?? "dispecink-lokality-id-dokumentace-vsechna___cs",
    path: vsechnaae1eK6kVwIMeta?.path ?? "vsechna",
    meta: vsechnaae1eK6kVwIMeta || {},
    alias: vsechnaae1eK6kVwIMeta?.alias || [],
    redirect: vsechnaae1eK6kVwIMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/dokumentace/vsechna.vue").then(m => m.default || m)
  },
  {
    name: vybranaagY6M4JF2ZMeta?.name ?? "dispecink-lokality-id-dokumentace-vybrana___cs",
    path: vybranaagY6M4JF2ZMeta?.path ?? "vybrana",
    meta: vybranaagY6M4JF2ZMeta || {},
    alias: vybranaagY6M4JF2ZMeta?.alias || [],
    redirect: vybranaagY6M4JF2ZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/dokumentace/vybrana.vue").then(m => m.default || m)
  }
]
  },
  {
    name: hlasenibNyuLO68kCMeta?.name ?? "dispecink-lokality-id-hlaseni___cs",
    path: hlasenibNyuLO68kCMeta?.path ?? "hlaseni",
    meta: hlasenibNyuLO68kCMeta || {},
    alias: hlasenibNyuLO68kCMeta?.alias || [],
    redirect: hlasenibNyuLO68kCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/hlaseni.vue").then(m => m.default || m)
  },
  {
    name: indexcxclu2P8XuMeta?.name ?? "dispecink-lokality-id___cs",
    path: indexcxclu2P8XuMeta?.path ?? "",
    meta: indexcxclu2P8XuMeta || {},
    alias: indexcxclu2P8XuMeta?.alias || [],
    redirect: indexcxclu2P8XuMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: nabidkyeTnojFtfLiMeta?.name ?? undefined,
    path: nabidkyeTnojFtfLiMeta?.path ?? "nabidky",
    meta: nabidkyeTnojFtfLiMeta || {},
    alias: nabidkyeTnojFtfLiMeta?.alias || [],
    redirect: nabidkyeTnojFtfLiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky.vue").then(m => m.default || m),
    children: [
  {
    name: indexoOkZMAyWYXMeta?.name ?? "dispecink-lokality-id-nabidky___cs",
    path: indexoOkZMAyWYXMeta?.path ?? "",
    meta: indexoOkZMAyWYXMeta || {},
    alias: indexoOkZMAyWYXMeta?.alias || [],
    redirect: indexoOkZMAyWYXMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/index.vue").then(m => m.default || m)
  },
  {
    name: k_45upomincelkniYg48WPMeta?.name ?? "dispecink-lokality-id-nabidky-k-upomince___cs",
    path: k_45upomincelkniYg48WPMeta?.path ?? "k-upomince",
    meta: k_45upomincelkniYg48WPMeta || {},
    alias: k_45upomincelkniYg48WPMeta?.alias || [],
    redirect: k_45upomincelkniYg48WPMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/k-upomince.vue").then(m => m.default || m)
  },
  {
    name: neprijatePtBNOKSQosMeta?.name ?? "dispecink-lokality-id-nabidky-neprijate___cs",
    path: neprijatePtBNOKSQosMeta?.path ?? "neprijate",
    meta: neprijatePtBNOKSQosMeta || {},
    alias: neprijatePtBNOKSQosMeta?.alias || [],
    redirect: neprijatePtBNOKSQosMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/neprijate.vue").then(m => m.default || m)
  },
  {
    name: odeslaneOOJSAnH5VcMeta?.name ?? "dispecink-lokality-id-nabidky-odeslane___cs",
    path: odeslaneOOJSAnH5VcMeta?.path ?? "odeslane",
    meta: odeslaneOOJSAnH5VcMeta || {},
    alias: odeslaneOOJSAnH5VcMeta?.alias || [],
    redirect: odeslaneOOJSAnH5VcMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/odeslane.vue").then(m => m.default || m)
  },
  {
    name: pripraveneloxOBdrR3bMeta?.name ?? "dispecink-lokality-id-nabidky-pripravene___cs",
    path: pripraveneloxOBdrR3bMeta?.path ?? "pripravene",
    meta: pripraveneloxOBdrR3bMeta || {},
    alias: pripraveneloxOBdrR3bMeta?.alias || [],
    redirect: pripraveneloxOBdrR3bMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/pripravene.vue").then(m => m.default || m)
  },
  {
    name: realizovane1gtx6Ld6wFMeta?.name ?? "dispecink-lokality-id-nabidky-realizovane___cs",
    path: realizovane1gtx6Ld6wFMeta?.path ?? "realizovane",
    meta: realizovane1gtx6Ld6wFMeta || {},
    alias: realizovane1gtx6Ld6wFMeta?.alias || [],
    redirect: realizovane1gtx6Ld6wFMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/realizovane.vue").then(m => m.default || m)
  },
  {
    name: rozpracovaneBO6UlN3pq6Meta?.name ?? "dispecink-lokality-id-nabidky-rozpracovane___cs",
    path: rozpracovaneBO6UlN3pq6Meta?.path ?? "rozpracovane",
    meta: rozpracovaneBO6UlN3pq6Meta || {},
    alias: rozpracovaneBO6UlN3pq6Meta?.alias || [],
    redirect: rozpracovaneBO6UlN3pq6Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/rozpracovane.vue").then(m => m.default || m)
  },
  {
    name: schvalenePIwf3ODSDdMeta?.name ?? "dispecink-lokality-id-nabidky-schvalene___cs",
    path: schvalenePIwf3ODSDdMeta?.path ?? "schvalene",
    meta: schvalenePIwf3ODSDdMeta || {},
    alias: schvalenePIwf3ODSDdMeta?.alias || [],
    redirect: schvalenePIwf3ODSDdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/schvalene.vue").then(m => m.default || m)
  },
  {
    name: upominanestRPbHVsNdMeta?.name ?? "dispecink-lokality-id-nabidky-upominane___cs",
    path: upominanestRPbHVsNdMeta?.path ?? "upominane",
    meta: upominanestRPbHVsNdMeta || {},
    alias: upominanestRPbHVsNdMeta?.alias || [],
    redirect: upominanestRPbHVsNdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/upominane.vue").then(m => m.default || m)
  },
  {
    name: vsechny_45nabidkyFmnRGhfQfVMeta?.name ?? "dispecink-lokality-id-nabidky-vsechny-nabidky___cs",
    path: vsechny_45nabidkyFmnRGhfQfVMeta?.path ?? "vsechny-nabidky",
    meta: vsechny_45nabidkyFmnRGhfQfVMeta || {},
    alias: vsechny_45nabidkyFmnRGhfQfVMeta?.alias || [],
    redirect: vsechny_45nabidkyFmnRGhfQfVMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/vsechny-nabidky.vue").then(m => m.default || m)
  },
  {
    name: vsechny_45polozkyeqqNeqSXkPMeta?.name ?? "dispecink-lokality-id-nabidky-vsechny-polozky___cs",
    path: vsechny_45polozkyeqqNeqSXkPMeta?.path ?? "vsechny-polozky",
    meta: vsechny_45polozkyeqqNeqSXkPMeta || {},
    alias: vsechny_45polozkyeqqNeqSXkPMeta?.alias || [],
    redirect: vsechny_45polozkyeqqNeqSXkPMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/vsechny-polozky.vue").then(m => m.default || m)
  },
  {
    name: vzory9K6fqPza5WMeta?.name ?? "dispecink-lokality-id-nabidky-vzory___cs",
    path: vzory9K6fqPza5WMeta?.path ?? "vzory",
    meta: vzory9K6fqPza5WMeta || {},
    alias: vzory9K6fqPza5WMeta?.alias || [],
    redirect: vzory9K6fqPza5WMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/nabidky/vzory.vue").then(m => m.default || m)
  }
]
  },
  {
    name: planyYTfWnqEbvnMeta?.name ?? undefined,
    path: planyYTfWnqEbvnMeta?.path ?? "plany",
    meta: planyYTfWnqEbvnMeta || {},
    alias: planyYTfWnqEbvnMeta?.alias || [],
    redirect: planyYTfWnqEbvnMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany.vue").then(m => m.default || m),
    children: [
  {
    name: aktivni6IrsWtLxOBMeta?.name ?? "dispecink-lokality-id-plany-aktivni___cs",
    path: aktivni6IrsWtLxOBMeta?.path ?? "aktivni",
    meta: aktivni6IrsWtLxOBMeta || {},
    alias: aktivni6IrsWtLxOBMeta?.alias || [],
    redirect: aktivni6IrsWtLxOBMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexyEOVw520ZdMeta?.name ?? "dispecink-lokality-id-plany___cs",
    path: indexyEOVw520ZdMeta?.path ?? "",
    meta: indexyEOVw520ZdMeta || {},
    alias: indexyEOVw520ZdMeta?.alias || [],
    redirect: indexyEOVw520ZdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/index.vue").then(m => m.default || m)
  },
  {
    name: k_45planub1EKlDfQBGMeta?.name ?? "dispecink-lokality-id-plany-k-planu___cs",
    path: k_45planub1EKlDfQBGMeta?.path ?? "k-planu",
    meta: k_45planub1EKlDfQBGMeta || {},
    alias: k_45planub1EKlDfQBGMeta?.alias || [],
    redirect: k_45planub1EKlDfQBGMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-planu.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45protipozarnich_45klapek2UFdh9GxhrMeta?.name ?? "dispecink-lokality-id-plany-k-revizi-protipozarnich-klapek___cs",
    path: k_45revizi_45protipozarnich_45klapek2UFdh9GxhrMeta?.path ?? "k-revizi-protipozarnich-klapek",
    meta: k_45revizi_45protipozarnich_45klapek2UFdh9GxhrMeta || {},
    alias: k_45revizi_45protipozarnich_45klapek2UFdh9GxhrMeta?.alias || [],
    redirect: k_45revizi_45protipozarnich_45klapek2UFdh9GxhrMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-protipozarnich-klapek.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45tns_45provoznimlEkw3c1WEMeta?.name ?? "dispecink-lokality-id-plany-k-revizi-tns-provozni___cs",
    path: k_45revizi_45tns_45provoznimlEkw3c1WEMeta?.path ?? "k-revizi-tns-provozni",
    meta: k_45revizi_45tns_45provoznimlEkw3c1WEMeta || {},
    alias: k_45revizi_45tns_45provoznimlEkw3c1WEMeta?.alias || [],
    redirect: k_45revizi_45tns_45provoznimlEkw3c1WEMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-tns-provozni.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45tns_45tlakova_45zkouskaRCNoh9ylzwMeta?.name ?? "dispecink-lokality-id-plany-k-revizi-tns-tlakova-zkouska___cs",
    path: k_45revizi_45tns_45tlakova_45zkouskaRCNoh9ylzwMeta?.path ?? "k-revizi-tns-tlakova-zkouska",
    meta: k_45revizi_45tns_45tlakova_45zkouskaRCNoh9ylzwMeta || {},
    alias: k_45revizi_45tns_45tlakova_45zkouskaRCNoh9ylzwMeta?.alias || [],
    redirect: k_45revizi_45tns_45tlakova_45zkouskaRCNoh9ylzwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-tns-tlakova-zkouska.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45tns_45vnitrnix5kvZ7cpnqMeta?.name ?? "dispecink-lokality-id-plany-k-revizi-tns-vnitrni___cs",
    path: k_45revizi_45tns_45vnitrnix5kvZ7cpnqMeta?.path ?? "k-revizi-tns-vnitrni",
    meta: k_45revizi_45tns_45vnitrnix5kvZ7cpnqMeta || {},
    alias: k_45revizi_45tns_45vnitrnix5kvZ7cpnqMeta?.alias || [],
    redirect: k_45revizi_45tns_45vnitrnix5kvZ7cpnqMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-tns-vnitrni.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45unikuxGA8gR0f3pMeta?.name ?? "dispecink-lokality-id-plany-k-revizi-uniku___cs",
    path: k_45revizi_45unikuxGA8gR0f3pMeta?.path ?? "k-revizi-uniku",
    meta: k_45revizi_45unikuxGA8gR0f3pMeta || {},
    alias: k_45revizi_45unikuxGA8gR0f3pMeta?.alias || [],
    redirect: k_45revizi_45unikuxGA8gR0f3pMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-revizi-uniku.vue").then(m => m.default || m)
  },
  {
    name: k_45vymene_45ventilu09XLVtcW4VMeta?.name ?? "dispecink-lokality-id-plany-k-vymene-ventilu___cs",
    path: k_45vymene_45ventilu09XLVtcW4VMeta?.path ?? "k-vymene-ventilu",
    meta: k_45vymene_45ventilu09XLVtcW4VMeta || {},
    alias: k_45vymene_45ventilu09XLVtcW4VMeta?.alias || [],
    redirect: k_45vymene_45ventilu09XLVtcW4VMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/k-vymene-ventilu.vue").then(m => m.default || m)
  },
  {
    name: vsechnyUv5UCEvb2NMeta?.name ?? "dispecink-lokality-id-plany-vsechny___cs",
    path: vsechnyUv5UCEvb2NMeta?.path ?? "vsechny",
    meta: vsechnyUv5UCEvb2NMeta || {},
    alias: vsechnyUv5UCEvb2NMeta?.alias || [],
    redirect: vsechnyUv5UCEvb2NMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/plany/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: protokolXrp93P3bICMeta?.name ?? "dispecink-lokality-id-protokol___cs",
    path: protokolXrp93P3bICMeta?.path ?? "protokol",
    meta: protokolXrp93P3bICMeta || {},
    alias: protokolXrp93P3bICMeta?.alias || [],
    redirect: protokolXrp93P3bICMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/protokol.vue").then(m => m.default || m)
  },
  {
    name: sledovany_45material9mtp8BY6l6Meta?.name ?? undefined,
    path: sledovany_45material9mtp8BY6l6Meta?.path ?? "sledovany-material",
    meta: sledovany_45material9mtp8BY6l6Meta || {},
    alias: sledovany_45material9mtp8BY6l6Meta?.alias || [],
    redirect: sledovany_45material9mtp8BY6l6Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/sledovany-material.vue").then(m => m.default || m),
    children: [
  {
    name: bez_45planuK7CWbnXhgvMeta?.name ?? "dispecink-lokality-id-sledovany-material-bez-planu___cs",
    path: bez_45planuK7CWbnXhgvMeta?.path ?? "bez-planu",
    meta: bez_45planuK7CWbnXhgvMeta || {},
    alias: bez_45planuK7CWbnXhgvMeta?.alias || [],
    redirect: bez_45planuK7CWbnXhgvMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/sledovany-material/bez-planu.vue").then(m => m.default || m)
  },
  {
    name: index3fMEKnWbfBMeta?.name ?? "dispecink-lokality-id-sledovany-material___cs",
    path: index3fMEKnWbfBMeta?.path ?? "",
    meta: index3fMEKnWbfBMeta || {},
    alias: index3fMEKnWbfBMeta?.alias || [],
    redirect: index3fMEKnWbfBMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/sledovany-material/index.vue").then(m => m.default || m)
  },
  {
    name: s_45mnozstvim7qDp22mlNtMeta?.name ?? "dispecink-lokality-id-sledovany-material-s-mnozstvim___cs",
    path: s_45mnozstvim7qDp22mlNtMeta?.path ?? "s-mnozstvim",
    meta: s_45mnozstvim7qDp22mlNtMeta || {},
    alias: s_45mnozstvim7qDp22mlNtMeta?.alias || [],
    redirect: s_45mnozstvim7qDp22mlNtMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/sledovany-material/s-mnozstvim.vue").then(m => m.default || m)
  },
  {
    name: s_45planemKE4i3F5fGpMeta?.name ?? "dispecink-lokality-id-sledovany-material-s-planem___cs",
    path: s_45planemKE4i3F5fGpMeta?.path ?? "s-planem",
    meta: s_45planemKE4i3F5fGpMeta || {},
    alias: s_45planemKE4i3F5fGpMeta?.alias || [],
    redirect: s_45planemKE4i3F5fGpMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/sledovany-material/s-planem.vue").then(m => m.default || m)
  },
  {
    name: vsechenGNOwGNlvBeMeta?.name ?? "dispecink-lokality-id-sledovany-material-vsechen___cs",
    path: vsechenGNOwGNlvBeMeta?.path ?? "vsechen",
    meta: vsechenGNOwGNlvBeMeta || {},
    alias: vsechenGNOwGNlvBeMeta?.alias || [],
    redirect: vsechenGNOwGNlvBeMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/sledovany-material/vsechen.vue").then(m => m.default || m)
  }
]
  },
  {
    name: udalostiq4Ds4tTAUCMeta?.name ?? undefined,
    path: udalostiq4Ds4tTAUCMeta?.path ?? "udalosti",
    meta: udalostiq4Ds4tTAUCMeta || {},
    alias: udalostiq4Ds4tTAUCMeta?.alias || [],
    redirect: udalostiq4Ds4tTAUCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti.vue").then(m => m.default || m),
    children: [
  {
    name: aktivnibePCEseRFHMeta?.name ?? "dispecink-lokality-id-udalosti-aktivni___cs",
    path: aktivnibePCEseRFHMeta?.path ?? "aktivni",
    meta: aktivnibePCEseRFHMeta || {},
    alias: aktivnibePCEseRFHMeta?.alias || [],
    redirect: aktivnibePCEseRFHMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexZKFKTtcwLvMeta?.name ?? "dispecink-lokality-id-udalosti___cs",
    path: indexZKFKTtcwLvMeta?.path ?? "",
    meta: indexZKFKTtcwLvMeta || {},
    alias: indexZKFKTtcwLvMeta?.alias || [],
    redirect: indexZKFKTtcwLvMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti/index.vue").then(m => m.default || m)
  },
  {
    name: provedeneaTIb8KIxiXMeta?.name ?? "dispecink-lokality-id-udalosti-provedene___cs",
    path: provedeneaTIb8KIxiXMeta?.path ?? "provedene",
    meta: provedeneaTIb8KIxiXMeta || {},
    alias: provedeneaTIb8KIxiXMeta?.alias || [],
    redirect: provedeneaTIb8KIxiXMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti/provedene.vue").then(m => m.default || m)
  },
  {
    name: uzavreneQMJpzwvxXcMeta?.name ?? "dispecink-lokality-id-udalosti-uzavrene___cs",
    path: uzavreneQMJpzwvxXcMeta?.path ?? "uzavrene",
    meta: uzavreneQMJpzwvxXcMeta || {},
    alias: uzavreneQMJpzwvxXcMeta?.alias || [],
    redirect: uzavreneQMJpzwvxXcMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti/uzavrene.vue").then(m => m.default || m)
  },
  {
    name: z_45puvodnich_45lokalitH0D0SIXYGMMeta?.name ?? "dispecink-lokality-id-udalosti-z-puvodnich-lokalit___cs",
    path: z_45puvodnich_45lokalitH0D0SIXYGMMeta?.path ?? "z-puvodnich-lokalit",
    meta: z_45puvodnich_45lokalitH0D0SIXYGMMeta || {},
    alias: z_45puvodnich_45lokalitH0D0SIXYGMMeta?.alias || [],
    redirect: z_45puvodnich_45lokalitH0D0SIXYGMMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti/z-puvodnich-lokalit.vue").then(m => m.default || m)
  },
  {
    name: zruseneYdSZcWfbfiMeta?.name ?? "dispecink-lokality-id-udalosti-zrusene___cs",
    path: zruseneYdSZcWfbfiMeta?.path ?? "zrusene",
    meta: zruseneYdSZcWfbfiMeta || {},
    alias: zruseneYdSZcWfbfiMeta?.alias || [],
    redirect: zruseneYdSZcWfbfiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/udalosti/zrusene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: vykazyDcO2tHJYwBMeta?.name ?? undefined,
    path: vykazyDcO2tHJYwBMeta?.path ?? "vykazy",
    meta: vykazyDcO2tHJYwBMeta || {},
    alias: vykazyDcO2tHJYwBMeta?.alias || [],
    redirect: vykazyDcO2tHJYwBMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/vykazy.vue").then(m => m.default || m),
    children: [
  {
    name: index9TaFTTulDrMeta?.name ?? "dispecink-lokality-id-vykazy___cs",
    path: index9TaFTTulDrMeta?.path ?? "",
    meta: index9TaFTTulDrMeta || {},
    alias: index9TaFTTulDrMeta?.alias || [],
    redirect: index9TaFTTulDrMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/vykazy/index.vue").then(m => m.default || m)
  },
  {
    name: k_45fakturaciA1O4sPk26nMeta?.name ?? "dispecink-lokality-id-vykazy-k-fakturaci___cs",
    path: k_45fakturaciA1O4sPk26nMeta?.path ?? "k-fakturaci",
    meta: k_45fakturaciA1O4sPk26nMeta || {},
    alias: k_45fakturaciA1O4sPk26nMeta?.alias || [],
    redirect: k_45fakturaciA1O4sPk26nMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/vykazy/k-fakturaci.vue").then(m => m.default || m)
  },
  {
    name: vsechnyyEhs4bQ4vRMeta?.name ?? "dispecink-lokality-id-vykazy-vsechny___cs",
    path: vsechnyyEhs4bQ4vRMeta?.path ?? "vsechny",
    meta: vsechnyyEhs4bQ4vRMeta || {},
    alias: vsechnyyEhs4bQ4vRMeta?.alias || [],
    redirect: vsechnyyEhs4bQ4vRMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/vykazy/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: vystupyJTedzELBz8Meta?.name ?? "dispecink-lokality-id-vystupy___cs",
    path: vystupyJTedzELBz8Meta?.path ?? "vystupy",
    meta: vystupyJTedzELBz8Meta || {},
    alias: vystupyJTedzELBz8Meta?.alias || [],
    redirect: vystupyJTedzELBz8Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/vystupy.vue").then(m => m.default || m)
  },
  {
    name: zarizenisxGQkWrMEXMeta?.name ?? undefined,
    path: zarizenisxGQkWrMEXMeta?.path ?? "zarizeni",
    meta: zarizenisxGQkWrMEXMeta || {},
    alias: zarizenisxGQkWrMEXMeta?.alias || [],
    redirect: zarizenisxGQkWrMEXMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/zarizeni.vue").then(m => m.default || m),
    children: [
  {
    name: demontovanehjDAylqbqZMeta?.name ?? "dispecink-lokality-id-zarizeni-demontovane___cs",
    path: demontovanehjDAylqbqZMeta?.path ?? "demontovane",
    meta: demontovanehjDAylqbqZMeta || {},
    alias: demontovanehjDAylqbqZMeta?.alias || [],
    redirect: demontovanehjDAylqbqZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/zarizeni/demontovane.vue").then(m => m.default || m)
  },
  {
    name: indexAY362MbnI6Meta?.name ?? "dispecink-lokality-id-zarizeni___cs",
    path: indexAY362MbnI6Meta?.path ?? "",
    meta: indexAY362MbnI6Meta || {},
    alias: indexAY362MbnI6Meta?.alias || [],
    redirect: indexAY362MbnI6Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/zarizeni/index.vue").then(m => m.default || m)
  },
  {
    name: instalovaneXc6wr0CeXCMeta?.name ?? "dispecink-lokality-id-zarizeni-instalovane___cs",
    path: instalovaneXc6wr0CeXCMeta?.path ?? "instalovane",
    meta: instalovaneXc6wr0CeXCMeta || {},
    alias: instalovaneXc6wr0CeXCMeta?.alias || [],
    redirect: instalovaneXc6wr0CeXCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/[id]/zarizeni/instalovane.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: aktivnienheCxtiF8Meta?.name ?? "dispecink-lokality-aktivni___cs",
    path: aktivnienheCxtiF8Meta?.path ?? "aktivni",
    meta: aktivnienheCxtiF8Meta || {},
    alias: aktivnienheCxtiF8Meta?.alias || [],
    redirect: aktivnienheCxtiF8Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/aktivni.vue").then(m => m.default || m)
  },
  {
    name: alarmyzIBgPCOjzWMeta?.name ?? "dispecink-lokality-alarmy___cs",
    path: alarmyzIBgPCOjzWMeta?.path ?? "alarmy",
    meta: alarmyzIBgPCOjzWMeta || {},
    alias: alarmyzIBgPCOjzWMeta?.alias || [],
    redirect: alarmyzIBgPCOjzWMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/alarmy.vue").then(m => m.default || m)
  },
  {
    name: indexO3CQqE34uQMeta?.name ?? "dispecink-lokality___cs",
    path: indexO3CQqE34uQMeta?.path ?? "",
    meta: indexO3CQqE34uQMeta || {},
    alias: indexO3CQqE34uQMeta?.alias || [],
    redirect: indexO3CQqE34uQMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/index.vue").then(m => m.default || m)
  },
  {
    name: narocnosti12IAfFSvDsMeta?.name ?? "dispecink-lokality-narocnosti___cs",
    path: narocnosti12IAfFSvDsMeta?.path ?? "narocnosti",
    meta: narocnosti12IAfFSvDsMeta || {},
    alias: narocnosti12IAfFSvDsMeta?.alias || [],
    redirect: narocnosti12IAfFSvDsMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/narocnosti.vue").then(m => m.default || m)
  },
  {
    name: zrusene2QkRQ9hozYMeta?.name ?? "dispecink-lokality-zrusene___cs",
    path: zrusene2QkRQ9hozYMeta?.path ?? "zrusene",
    meta: zrusene2QkRQ9hozYMeta || {},
    alias: zrusene2QkRQ9hozYMeta?.alias || [],
    redirect: zrusene2QkRQ9hozYMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/lokality/zrusene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: nabidkyGpLbRzXGWHMeta?.name ?? undefined,
    path: nabidkyGpLbRzXGWHMeta?.path ?? "nabidky",
    meta: nabidkyGpLbRzXGWHMeta || {},
    alias: nabidkyGpLbRzXGWHMeta?.alias || [],
    redirect: nabidkyGpLbRzXGWHMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93ydegqUOXFHMeta?.name ?? undefined,
    path: _91id_93ydegqUOXFHMeta?.path ?? ":id()",
    meta: _91id_93ydegqUOXFHMeta || {},
    alias: _91id_93ydegqUOXFHMeta?.alias || [],
    redirect: _91id_93ydegqUOXFHMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/[id].vue").then(m => m.default || m),
    children: [
  {
    name: dokumentaceTvyIJew092Meta?.name ?? "dispecink-nabidky-id-dokumentace___cs",
    path: dokumentaceTvyIJew092Meta?.path ?? "dokumentace",
    meta: dokumentaceTvyIJew092Meta || {},
    alias: dokumentaceTvyIJew092Meta?.alias || [],
    redirect: dokumentaceTvyIJew092Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/[id]/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: indextyNlqx7KulMeta?.name ?? "dispecink-nabidky-id___cs",
    path: indextyNlqx7KulMeta?.path ?? "",
    meta: indextyNlqx7KulMeta || {},
    alias: indextyNlqx7KulMeta?.alias || [],
    redirect: indextyNlqx7KulMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: polozkyeNT4wieIfSMeta?.name ?? "dispecink-nabidky-id-polozky___cs",
    path: polozkyeNT4wieIfSMeta?.path ?? "polozky",
    meta: polozkyeNT4wieIfSMeta || {},
    alias: polozkyeNT4wieIfSMeta?.alias || [],
    redirect: polozkyeNT4wieIfSMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/[id]/polozky.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexGVCRWijQpsMeta?.name ?? "dispecink-nabidky___cs",
    path: indexGVCRWijQpsMeta?.path ?? "",
    meta: indexGVCRWijQpsMeta || {},
    alias: indexGVCRWijQpsMeta?.alias || [],
    redirect: indexGVCRWijQpsMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/index.vue").then(m => m.default || m)
  },
  {
    name: k_45upomince0tVwrwmicYMeta?.name ?? "dispecink-nabidky-k-upomince___cs",
    path: k_45upomince0tVwrwmicYMeta?.path ?? "k-upomince",
    meta: k_45upomince0tVwrwmicYMeta || {},
    alias: k_45upomince0tVwrwmicYMeta?.alias || [],
    redirect: k_45upomince0tVwrwmicYMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/k-upomince.vue").then(m => m.default || m)
  },
  {
    name: neprijatehITIHfg51SMeta?.name ?? "dispecink-nabidky-neprijate___cs",
    path: neprijatehITIHfg51SMeta?.path ?? "neprijate",
    meta: neprijatehITIHfg51SMeta || {},
    alias: neprijatehITIHfg51SMeta?.alias || [],
    redirect: neprijatehITIHfg51SMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/neprijate.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zWYHVxyT36Meta?.name ?? "dispecink-nabidky-nova-id___cs",
    path: _91id_93zWYHVxyT36Meta?.path ?? "nova/:id()",
    meta: _91id_93zWYHVxyT36Meta || {},
    alias: _91id_93zWYHVxyT36Meta?.alias || [],
    redirect: _91id_93zWYHVxyT36Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/nova/[id].vue").then(m => m.default || m)
  },
  {
    name: odeslanev0svMzWvcnMeta?.name ?? "dispecink-nabidky-odeslane___cs",
    path: odeslanev0svMzWvcnMeta?.path ?? "odeslane",
    meta: odeslanev0svMzWvcnMeta || {},
    alias: odeslanev0svMzWvcnMeta?.alias || [],
    redirect: odeslanev0svMzWvcnMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/odeslane.vue").then(m => m.default || m)
  },
  {
    name: pripraveneVuseUq5cCOMeta?.name ?? "dispecink-nabidky-pripravene___cs",
    path: pripraveneVuseUq5cCOMeta?.path ?? "pripravene",
    meta: pripraveneVuseUq5cCOMeta || {},
    alias: pripraveneVuseUq5cCOMeta?.alias || [],
    redirect: pripraveneVuseUq5cCOMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/pripravene.vue").then(m => m.default || m)
  },
  {
    name: realizovanebLnPdkyJebMeta?.name ?? "dispecink-nabidky-realizovane___cs",
    path: realizovanebLnPdkyJebMeta?.path ?? "realizovane",
    meta: realizovanebLnPdkyJebMeta || {},
    alias: realizovanebLnPdkyJebMeta?.alias || [],
    redirect: realizovanebLnPdkyJebMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/realizovane.vue").then(m => m.default || m)
  },
  {
    name: rozpracovaneCtUi6WgL9DMeta?.name ?? "dispecink-nabidky-rozpracovane___cs",
    path: rozpracovaneCtUi6WgL9DMeta?.path ?? "rozpracovane",
    meta: rozpracovaneCtUi6WgL9DMeta || {},
    alias: rozpracovaneCtUi6WgL9DMeta?.alias || [],
    redirect: rozpracovaneCtUi6WgL9DMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/rozpracovane.vue").then(m => m.default || m)
  },
  {
    name: schvalene6Xygen96hFMeta?.name ?? "dispecink-nabidky-schvalene___cs",
    path: schvalene6Xygen96hFMeta?.path ?? "schvalene",
    meta: schvalene6Xygen96hFMeta || {},
    alias: schvalene6Xygen96hFMeta?.alias || [],
    redirect: schvalene6Xygen96hFMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/schvalene.vue").then(m => m.default || m)
  },
  {
    name: upominanexiQE0kocdDMeta?.name ?? "dispecink-nabidky-upominane___cs",
    path: upominanexiQE0kocdDMeta?.path ?? "upominane",
    meta: upominanexiQE0kocdDMeta || {},
    alias: upominanexiQE0kocdDMeta?.alias || [],
    redirect: upominanexiQE0kocdDMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/upominane.vue").then(m => m.default || m)
  },
  {
    name: vsechny_45nabidkyFnAdgiMc3wMeta?.name ?? "dispecink-nabidky-vsechny-nabidky___cs",
    path: vsechny_45nabidkyFnAdgiMc3wMeta?.path ?? "vsechny-nabidky",
    meta: vsechny_45nabidkyFnAdgiMc3wMeta || {},
    alias: vsechny_45nabidkyFnAdgiMc3wMeta?.alias || [],
    redirect: vsechny_45nabidkyFnAdgiMc3wMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/vsechny-nabidky.vue").then(m => m.default || m)
  },
  {
    name: vsechny_45polozkybF7yaztpIIMeta?.name ?? "dispecink-nabidky-vsechny-polozky___cs",
    path: vsechny_45polozkybF7yaztpIIMeta?.path ?? "vsechny-polozky",
    meta: vsechny_45polozkybF7yaztpIIMeta || {},
    alias: vsechny_45polozkybF7yaztpIIMeta?.alias || [],
    redirect: vsechny_45polozkybF7yaztpIIMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/vsechny-polozky.vue").then(m => m.default || m)
  },
  {
    name: vzorySF7MHZIABdMeta?.name ?? "dispecink-nabidky-vzory___cs",
    path: vzorySF7MHZIABdMeta?.path ?? "vzory",
    meta: vzorySF7MHZIABdMeta || {},
    alias: vzorySF7MHZIABdMeta?.alias || [],
    redirect: vzorySF7MHZIABdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nabidky/vzory.vue").then(m => m.default || m)
  }
]
  },
  {
    name: nahradni_45reseniaN6N6TMzqpMeta?.name ?? undefined,
    path: nahradni_45reseniaN6N6TMzqpMeta?.path ?? "nahradni-reseni",
    meta: nahradni_45reseniaN6N6TMzqpMeta || {},
    alias: nahradni_45reseniaN6N6TMzqpMeta?.alias || [],
    redirect: nahradni_45reseniaN6N6TMzqpMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nahradni-reseni.vue").then(m => m.default || m),
    children: [
  {
    name: indexlUg5kW6YLzMeta?.name ?? "dispecink-nahradni-reseni___cs",
    path: indexlUg5kW6YLzMeta?.path ?? "",
    meta: indexlUg5kW6YLzMeta || {},
    alias: indexlUg5kW6YLzMeta?.alias || [],
    redirect: indexlUg5kW6YLzMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nahradni-reseni/index.vue").then(m => m.default || m)
  },
  {
    name: instalovaneakRG3sO6zyMeta?.name ?? "dispecink-nahradni-reseni-instalovane___cs",
    path: instalovaneakRG3sO6zyMeta?.path ?? "instalovane",
    meta: instalovaneakRG3sO6zyMeta || {},
    alias: instalovaneakRG3sO6zyMeta?.alias || [],
    redirect: instalovaneakRG3sO6zyMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nahradni-reseni/instalovane.vue").then(m => m.default || m)
  },
  {
    name: odstranene1CjZyaPLpeMeta?.name ?? "dispecink-nahradni-reseni-odstranene___cs",
    path: odstranene1CjZyaPLpeMeta?.path ?? "odstranene",
    meta: odstranene1CjZyaPLpeMeta || {},
    alias: odstranene1CjZyaPLpeMeta?.alias || [],
    redirect: odstranene1CjZyaPLpeMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nahradni-reseni/odstranene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: nemoS8BCSngZYsMeta?.name ?? undefined,
    path: nemoS8BCSngZYsMeta?.path ?? "nemo",
    meta: nemoS8BCSngZYsMeta || {},
    alias: nemoS8BCSngZYsMeta?.alias || [],
    redirect: nemoS8BCSngZYsMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo.vue").then(m => m.default || m),
    children: [
  {
    name: index28AiWTmmxVMeta?.name ?? "dispecink-nemo___cs",
    path: index28AiWTmmxVMeta?.path ?? "",
    meta: index28AiWTmmxVMeta || {},
    alias: index28AiWTmmxVMeta?.alias || [],
    redirect: index28AiWTmmxVMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/index.vue").then(m => m.default || m)
  },
  {
    name: souboryznyt1BO4ZmMeta?.name ?? undefined,
    path: souboryznyt1BO4ZmMeta?.path ?? "soubory",
    meta: souboryznyt1BO4ZmMeta || {},
    alias: souboryznyt1BO4ZmMeta?.alias || [],
    redirect: souboryznyt1BO4ZmMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/soubory.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93ofuU5DtK4pMeta?.name ?? "dispecink-nemo-soubory-id___cs",
    path: _91id_93ofuU5DtK4pMeta?.path ?? ":id()",
    meta: _91id_93ofuU5DtK4pMeta || {},
    alias: _91id_93ofuU5DtK4pMeta?.alias || [],
    redirect: _91id_93ofuU5DtK4pMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/soubory/[id].vue").then(m => m.default || m)
  },
  {
    name: index8aakrMwmP1Meta?.name ?? "dispecink-nemo-soubory___cs",
    path: index8aakrMwmP1Meta?.path ?? "",
    meta: index8aakrMwmP1Meta || {},
    alias: index8aakrMwmP1Meta?.alias || [],
    redirect: index8aakrMwmP1Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/soubory/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: udajewpry5fBMYKMeta?.name ?? undefined,
    path: udajewpry5fBMYKMeta?.path ?? "udaje",
    meta: udajewpry5fBMYKMeta || {},
    alias: udajewpry5fBMYKMeta?.alias || [],
    redirect: udajewpry5fBMYKMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/udaje.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xOjLWPN8kiMeta?.name ?? "dispecink-nemo-udaje-id___cs",
    path: _91id_93xOjLWPN8kiMeta?.path ?? ":id()",
    meta: _91id_93xOjLWPN8kiMeta || {},
    alias: _91id_93xOjLWPN8kiMeta?.alias || [],
    redirect: _91id_93xOjLWPN8kiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/udaje/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLRtqWLnodPMeta?.name ?? "dispecink-nemo-udaje___cs",
    path: indexLRtqWLnodPMeta?.path ?? "",
    meta: indexLRtqWLnodPMeta || {},
    alias: indexLRtqWLnodPMeta?.alias || [],
    redirect: indexLRtqWLnodPMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/nemo/udaje/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: obchodni_45pripady6d6JXvCVsnMeta?.name ?? undefined,
    path: obchodni_45pripady6d6JXvCVsnMeta?.path ?? "obchodni-pripady",
    meta: obchodni_45pripady6d6JXvCVsnMeta || {},
    alias: obchodni_45pripady6d6JXvCVsnMeta?.alias || [],
    redirect: obchodni_45pripady6d6JXvCVsnMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/obchodni-pripady.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93iyfml1x0PAMeta?.name ?? undefined,
    path: _91id_93iyfml1x0PAMeta?.path ?? ":id()",
    meta: _91id_93iyfml1x0PAMeta || {},
    alias: _91id_93iyfml1x0PAMeta?.alias || [],
    redirect: _91id_93iyfml1x0PAMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/obchodni-pripady/[id].vue").then(m => m.default || m),
    children: [
  {
    name: index0cxsjR6QvxMeta?.name ?? "dispecink-obchodni-pripady-id___cs",
    path: index0cxsjR6QvxMeta?.path ?? "",
    meta: index0cxsjR6QvxMeta || {},
    alias: index0cxsjR6QvxMeta?.alias || [],
    redirect: index0cxsjR6QvxMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/obchodni-pripady/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexq9uyuhBHieMeta?.name ?? "dispecink-obchodni-pripady___cs",
    path: indexq9uyuhBHieMeta?.path ?? "",
    meta: indexq9uyuhBHieMeta || {},
    alias: indexq9uyuhBHieMeta?.alias || [],
    redirect: indexq9uyuhBHieMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/obchodni-pripady/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: partneri2KHglw0sVoMeta?.name ?? undefined,
    path: partneri2KHglw0sVoMeta?.path ?? "partneri",
    meta: partneri2KHglw0sVoMeta || {},
    alias: partneri2KHglw0sVoMeta?.alias || [],
    redirect: partneri2KHglw0sVoMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93OWpTJhPpnCMeta?.name ?? undefined,
    path: _91id_93OWpTJhPpnCMeta?.path ?? ":id()",
    meta: _91id_93OWpTJhPpnCMeta || {},
    alias: _91id_93OWpTJhPpnCMeta?.alias || [],
    redirect: _91id_93OWpTJhPpnCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id].vue").then(m => m.default || m),
    children: [
  {
    name: cenikJERRqrfxksMeta?.name ?? "dispecink-partneri-id-cenik___cs",
    path: cenikJERRqrfxksMeta?.path ?? "cenik",
    meta: cenikJERRqrfxksMeta || {},
    alias: cenikJERRqrfxksMeta?.alias || [],
    redirect: cenikJERRqrfxksMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/cenik.vue").then(m => m.default || m)
  },
  {
    name: indexXcX0oP7GizMeta?.name ?? "dispecink-partneri-id___cs",
    path: indexXcX0oP7GizMeta?.path ?? "",
    meta: indexXcX0oP7GizMeta || {},
    alias: indexXcX0oP7GizMeta?.alias || [],
    redirect: indexXcX0oP7GizMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktyfp2PurQqh2Meta?.name ?? "dispecink-partneri-id-kontakty___cs",
    path: kontaktyfp2PurQqh2Meta?.path ?? "kontakty",
    meta: kontaktyfp2PurQqh2Meta || {},
    alias: kontaktyfp2PurQqh2Meta?.alias || [],
    redirect: kontaktyfp2PurQqh2Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/kontakty.vue").then(m => m.default || m)
  },
  {
    name: lokalitydyCFgYm9nTMeta?.name ?? "dispecink-partneri-id-lokality___cs",
    path: lokalitydyCFgYm9nTMeta?.path ?? "lokality",
    meta: lokalitydyCFgYm9nTMeta || {},
    alias: lokalitydyCFgYm9nTMeta?.alias || [],
    redirect: lokalitydyCFgYm9nTMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/lokality.vue").then(m => m.default || m)
  },
  {
    name: nabidkyl4X0ZKco0uMeta?.name ?? "dispecink-partneri-id-nabidky___cs",
    path: nabidkyl4X0ZKco0uMeta?.path ?? "nabidky",
    meta: nabidkyl4X0ZKco0uMeta || {},
    alias: nabidkyl4X0ZKco0uMeta?.alias || [],
    redirect: nabidkyl4X0ZKco0uMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/nabidky.vue").then(m => m.default || m)
  },
  {
    name: oblastijd7DYqLegzMeta?.name ?? "dispecink-partneri-id-oblasti___cs",
    path: oblastijd7DYqLegzMeta?.path ?? "oblasti",
    meta: oblastijd7DYqLegzMeta || {},
    alias: oblastijd7DYqLegzMeta?.alias || [],
    redirect: oblastijd7DYqLegzMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/oblasti.vue").then(m => m.default || m)
  },
  {
    name: udalostiCdk7gYzBmjMeta?.name ?? "dispecink-partneri-id-udalosti___cs",
    path: udalostiCdk7gYzBmjMeta?.path ?? "udalosti",
    meta: udalostiCdk7gYzBmjMeta || {},
    alias: udalostiCdk7gYzBmjMeta?.alias || [],
    redirect: udalostiCdk7gYzBmjMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/udalosti.vue").then(m => m.default || m)
  },
  {
    name: vystupyZ50cBVq95rMeta?.name ?? "dispecink-partneri-id-vystupy___cs",
    path: vystupyZ50cBVq95rMeta?.path ?? "vystupy",
    meta: vystupyZ50cBVq95rMeta || {},
    alias: vystupyZ50cBVq95rMeta?.alias || [],
    redirect: vystupyZ50cBVq95rMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/[id]/vystupy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexoCc7PSQMSLMeta?.name ?? "dispecink-partneri___cs",
    path: indexoCc7PSQMSLMeta?.path ?? "",
    meta: indexoCc7PSQMSLMeta || {},
    alias: indexoCc7PSQMSLMeta?.alias || [],
    redirect: indexoCc7PSQMSLMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/index.vue").then(m => m.default || m)
  },
  {
    name: s_45lokalitamitVu4tXjtHlMeta?.name ?? "dispecink-partneri-s-lokalitami___cs",
    path: s_45lokalitamitVu4tXjtHlMeta?.path ?? "s-lokalitami",
    meta: s_45lokalitamitVu4tXjtHlMeta || {},
    alias: s_45lokalitamitVu4tXjtHlMeta?.alias || [],
    redirect: s_45lokalitamitVu4tXjtHlMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/s-lokalitami.vue").then(m => m.default || m)
  },
  {
    name: vsichni7flITmpbhvMeta?.name ?? "dispecink-partneri-vsichni___cs",
    path: vsichni7flITmpbhvMeta?.path ?? "vsichni",
    meta: vsichni7flITmpbhvMeta || {},
    alias: vsichni7flITmpbhvMeta?.alias || [],
    redirect: vsichni7flITmpbhvMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/partneri/vsichni.vue").then(m => m.default || m)
  }
]
  },
  {
    name: plany7RWnSupQuIMeta?.name ?? undefined,
    path: plany7RWnSupQuIMeta?.path ?? "plany",
    meta: plany7RWnSupQuIMeta || {},
    alias: plany7RWnSupQuIMeta?.alias || [],
    redirect: plany7RWnSupQuIMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany.vue").then(m => m.default || m),
    children: [
  {
    name: aktivnieE0VX0aruzMeta?.name ?? "dispecink-plany-aktivni___cs",
    path: aktivnieE0VX0aruzMeta?.path ?? "aktivni",
    meta: aktivnieE0VX0aruzMeta || {},
    alias: aktivnieE0VX0aruzMeta?.alias || [],
    redirect: aktivnieE0VX0aruzMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexdaFbYR9GMwMeta?.name ?? "dispecink-plany___cs",
    path: indexdaFbYR9GMwMeta?.path ?? "",
    meta: indexdaFbYR9GMwMeta || {},
    alias: indexdaFbYR9GMwMeta?.alias || [],
    redirect: indexdaFbYR9GMwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/index.vue").then(m => m.default || m)
  },
  {
    name: k_45planuNC0hdar2XdMeta?.name ?? "dispecink-plany-k-planu___cs",
    path: k_45planuNC0hdar2XdMeta?.path ?? "k-planu",
    meta: k_45planuNC0hdar2XdMeta || {},
    alias: k_45planuNC0hdar2XdMeta?.alias || [],
    redirect: k_45planuNC0hdar2XdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-planu.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45protipozarnich_45klapekEn9t32DDqtMeta?.name ?? "dispecink-plany-k-revizi-protipozarnich-klapek___cs",
    path: k_45revizi_45protipozarnich_45klapekEn9t32DDqtMeta?.path ?? "k-revizi-protipozarnich-klapek",
    meta: k_45revizi_45protipozarnich_45klapekEn9t32DDqtMeta || {},
    alias: k_45revizi_45protipozarnich_45klapekEn9t32DDqtMeta?.alias || [],
    redirect: k_45revizi_45protipozarnich_45klapekEn9t32DDqtMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-revizi-protipozarnich-klapek.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45tns_45provozniYjCblOOh8IMeta?.name ?? "dispecink-plany-k-revizi-tns-provozni___cs",
    path: k_45revizi_45tns_45provozniYjCblOOh8IMeta?.path ?? "k-revizi-tns-provozni",
    meta: k_45revizi_45tns_45provozniYjCblOOh8IMeta || {},
    alias: k_45revizi_45tns_45provozniYjCblOOh8IMeta?.alias || [],
    redirect: k_45revizi_45tns_45provozniYjCblOOh8IMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-revizi-tns-provozni.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45tns_45tlakova_45zkouskaXrEmtOs3IiMeta?.name ?? "dispecink-plany-k-revizi-tns-tlakova-zkouska___cs",
    path: k_45revizi_45tns_45tlakova_45zkouskaXrEmtOs3IiMeta?.path ?? "k-revizi-tns-tlakova-zkouska",
    meta: k_45revizi_45tns_45tlakova_45zkouskaXrEmtOs3IiMeta || {},
    alias: k_45revizi_45tns_45tlakova_45zkouskaXrEmtOs3IiMeta?.alias || [],
    redirect: k_45revizi_45tns_45tlakova_45zkouskaXrEmtOs3IiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-revizi-tns-tlakova-zkouska.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45tns_45vnitrni9jwXOv9xKTMeta?.name ?? "dispecink-plany-k-revizi-tns-vnitrni___cs",
    path: k_45revizi_45tns_45vnitrni9jwXOv9xKTMeta?.path ?? "k-revizi-tns-vnitrni",
    meta: k_45revizi_45tns_45vnitrni9jwXOv9xKTMeta || {},
    alias: k_45revizi_45tns_45vnitrni9jwXOv9xKTMeta?.alias || [],
    redirect: k_45revizi_45tns_45vnitrni9jwXOv9xKTMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-revizi-tns-vnitrni.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45unikuTJ9LNgxSkOMeta?.name ?? "dispecink-plany-k-revizi-uniku___cs",
    path: k_45revizi_45unikuTJ9LNgxSkOMeta?.path ?? "k-revizi-uniku",
    meta: k_45revizi_45unikuTJ9LNgxSkOMeta || {},
    alias: k_45revizi_45unikuTJ9LNgxSkOMeta?.alias || [],
    redirect: k_45revizi_45unikuTJ9LNgxSkOMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-revizi-uniku.vue").then(m => m.default || m)
  },
  {
    name: k_45revizi_45ventiluYRZL1aSFWJMeta?.name ?? "dispecink-plany-k-revizi-ventilu___cs",
    path: k_45revizi_45ventiluYRZL1aSFWJMeta?.path ?? "k-revizi-ventilu",
    meta: k_45revizi_45ventiluYRZL1aSFWJMeta || {},
    alias: k_45revizi_45ventiluYRZL1aSFWJMeta?.alias || [],
    redirect: k_45revizi_45ventiluYRZL1aSFWJMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/k-revizi-ventilu.vue").then(m => m.default || m)
  },
  {
    name: vsechnyn10P7o1LEbMeta?.name ?? "dispecink-plany-vsechny___cs",
    path: vsechnyn10P7o1LEbMeta?.path ?? "vsechny",
    meta: vsechnyn10P7o1LEbMeta || {},
    alias: vsechnyn10P7o1LEbMeta?.alias || [],
    redirect: vsechnyn10P7o1LEbMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/plany/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: pozadavky25zvKc17pOMeta?.name ?? undefined,
    path: pozadavky25zvKc17pOMeta?.path ?? "pozadavky",
    meta: pozadavky25zvKc17pOMeta || {},
    alias: pozadavky25zvKc17pOMeta?.alias || [],
    redirect: pozadavky25zvKc17pOMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93SpNyxtaAtRMeta?.name ?? undefined,
    path: _91id_93SpNyxtaAtRMeta?.path ?? ":id()",
    meta: _91id_93SpNyxtaAtRMeta || {},
    alias: _91id_93SpNyxtaAtRMeta?.alias || [],
    redirect: _91id_93SpNyxtaAtRMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexBZ5PPmakF9Meta?.name ?? "dispecink-pozadavky-id___cs",
    path: indexBZ5PPmakF9Meta?.path ?? "",
    meta: indexBZ5PPmakF9Meta || {},
    alias: indexBZ5PPmakF9Meta?.alias || [],
    redirect: indexBZ5PPmakF9Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: parametry8KapLJXNtwMeta?.name ?? "dispecink-pozadavky-id-parametry___cs",
    path: parametry8KapLJXNtwMeta?.path ?? "parametry",
    meta: parametry8KapLJXNtwMeta || {},
    alias: parametry8KapLJXNtwMeta?.alias || [],
    redirect: parametry8KapLJXNtwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/[id]/parametry.vue").then(m => m.default || m)
  },
  {
    name: vykazydvAGieBOmaMeta?.name ?? "dispecink-pozadavky-id-vykazy___cs",
    path: vykazydvAGieBOmaMeta?.path ?? "vykazy",
    meta: vykazydvAGieBOmaMeta || {},
    alias: vykazydvAGieBOmaMeta?.alias || [],
    redirect: vykazydvAGieBOmaMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/[id]/vykazy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: budouciM7KRKDRQKMMeta?.name ?? "dispecink-pozadavky-budouci___cs",
    path: budouciM7KRKDRQKMMeta?.path ?? "budouci",
    meta: budouciM7KRKDRQKMMeta || {},
    alias: budouciM7KRKDRQKMMeta?.alias || [],
    redirect: budouciM7KRKDRQKMMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/budouci.vue").then(m => m.default || m)
  },
  {
    name: index7WP3fcv1yTMeta?.name ?? "dispecink-pozadavky___cs",
    path: index7WP3fcv1yTMeta?.path ?? "",
    meta: index7WP3fcv1yTMeta || {},
    alias: index7WP3fcv1yTMeta?.alias || [],
    redirect: index7WP3fcv1yTMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BPxKVbKrcgMeta?.name ?? "dispecink-pozadavky-parameter-id___cs",
    path: _91id_93BPxKVbKrcgMeta?.path ?? "parameter/:id()",
    meta: _91id_93BPxKVbKrcgMeta || {},
    alias: _91id_93BPxKVbKrcgMeta?.alias || [],
    redirect: _91id_93BPxKVbKrcgMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/parameter/[id].vue").then(m => m.default || m)
  },
  {
    name: platneQ6rncSDJQrMeta?.name ?? "dispecink-pozadavky-platne___cs",
    path: platneQ6rncSDJQrMeta?.path ?? "platne",
    meta: platneQ6rncSDJQrMeta || {},
    alias: platneQ6rncSDJQrMeta?.alias || [],
    redirect: platneQ6rncSDJQrMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/platne.vue").then(m => m.default || m)
  },
  {
    name: ukonceneLYYYRba7DFMeta?.name ?? "dispecink-pozadavky-ukoncene___cs",
    path: ukonceneLYYYRba7DFMeta?.path ?? "ukoncene",
    meta: ukonceneLYYYRba7DFMeta || {},
    alias: ukonceneLYYYRba7DFMeta?.alias || [],
    redirect: ukonceneLYYYRba7DFMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/ukoncene.vue").then(m => m.default || m)
  },
  {
    name: vsechnykcvQ0ZS2WHMeta?.name ?? "dispecink-pozadavky-vsechny___cs",
    path: vsechnykcvQ0ZS2WHMeta?.path ?? "vsechny",
    meta: vsechnykcvQ0ZS2WHMeta || {},
    alias: vsechnykcvQ0ZS2WHMeta?.alias || [],
    redirect: vsechnykcvQ0ZS2WHMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/pozadavky/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: poznamkyZPJLul0kUjMeta?.name ?? undefined,
    path: poznamkyZPJLul0kUjMeta?.path ?? "poznamky",
    meta: poznamkyZPJLul0kUjMeta || {},
    alias: poznamkyZPJLul0kUjMeta?.alias || [],
    redirect: poznamkyZPJLul0kUjMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/poznamky.vue").then(m => m.default || m),
    children: [
  {
    name: index5ehc52dRbpMeta?.name ?? "dispecink-poznamky___cs",
    path: index5ehc52dRbpMeta?.path ?? "",
    meta: index5ehc52dRbpMeta || {},
    alias: index5ehc52dRbpMeta?.alias || [],
    redirect: index5ehc52dRbpMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/poznamky/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: predavaci_45protokolyESbJ2Ad4s3Meta?.name ?? undefined,
    path: predavaci_45protokolyESbJ2Ad4s3Meta?.path ?? "predavaci-protokoly",
    meta: predavaci_45protokolyESbJ2Ad4s3Meta || {},
    alias: predavaci_45protokolyESbJ2Ad4s3Meta?.alias || [],
    redirect: predavaci_45protokolyESbJ2Ad4s3Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93J6EwVDizrVMeta?.name ?? undefined,
    path: _91id_93J6EwVDizrVMeta?.path ?? ":id()",
    meta: _91id_93J6EwVDizrVMeta || {},
    alias: _91id_93J6EwVDizrVMeta?.alias || [],
    redirect: _91id_93J6EwVDizrVMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/[id].vue").then(m => m.default || m),
    children: [
  {
    name: dilySPH4vdgPgdMeta?.name ?? "dispecink-predavaci-protokoly-id-dily___cs",
    path: dilySPH4vdgPgdMeta?.path ?? "dily",
    meta: dilySPH4vdgPgdMeta || {},
    alias: dilySPH4vdgPgdMeta?.alias || [],
    redirect: dilySPH4vdgPgdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/[id]/dily.vue").then(m => m.default || m)
  },
  {
    name: dokumentacekDuxMdoXoPMeta?.name ?? "dispecink-predavaci-protokoly-id-dokumentace___cs",
    path: dokumentacekDuxMdoXoPMeta?.path ?? "dokumentace",
    meta: dokumentacekDuxMdoXoPMeta || {},
    alias: dokumentacekDuxMdoXoPMeta?.alias || [],
    redirect: dokumentacekDuxMdoXoPMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/[id]/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: index2Lbk4RdGdLMeta?.name ?? "dispecink-predavaci-protokoly-id___cs",
    path: index2Lbk4RdGdLMeta?.path ?? "",
    meta: index2Lbk4RdGdLMeta || {},
    alias: index2Lbk4RdGdLMeta?.alias || [],
    redirect: index2Lbk4RdGdLMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkX9J18Wlw5Meta?.name ?? "dispecink-predavaci-protokoly___cs",
    path: indexkX9J18Wlw5Meta?.path ?? "",
    meta: indexkX9J18Wlw5Meta || {},
    alias: indexkX9J18Wlw5Meta?.alias || [],
    redirect: indexkX9J18Wlw5Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/index.vue").then(m => m.default || m)
  },
  {
    name: odeslane1mrxLakD2bMeta?.name ?? "dispecink-predavaci-protokoly-odeslane___cs",
    path: odeslane1mrxLakD2bMeta?.path ?? "odeslane",
    meta: odeslane1mrxLakD2bMeta || {},
    alias: odeslane1mrxLakD2bMeta?.alias || [],
    redirect: odeslane1mrxLakD2bMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/odeslane.vue").then(m => m.default || m)
  },
  {
    name: rozpracovaneEkjdjjkn6WMeta?.name ?? "dispecink-predavaci-protokoly-rozpracovane___cs",
    path: rozpracovaneEkjdjjkn6WMeta?.path ?? "rozpracovane",
    meta: rozpracovaneEkjdjjkn6WMeta || {},
    alias: rozpracovaneEkjdjjkn6WMeta?.alias || [],
    redirect: rozpracovaneEkjdjjkn6WMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/rozpracovane.vue").then(m => m.default || m)
  },
  {
    name: vsechnyaXaxTYRAjDMeta?.name ?? "dispecink-predavaci-protokoly-vsechny___cs",
    path: vsechnyaXaxTYRAjDMeta?.path ?? "vsechny",
    meta: vsechnyaXaxTYRAjDMeta || {},
    alias: vsechnyaXaxTYRAjDMeta?.alias || [],
    redirect: vsechnyaXaxTYRAjDMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/predavaci-protokoly/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sestavyMLOuHbCynIMeta?.name ?? undefined,
    path: sestavyMLOuHbCynIMeta?.path ?? "sestavy",
    meta: sestavyMLOuHbCynIMeta || {},
    alias: sestavyMLOuHbCynIMeta?.alias || [],
    redirect: sestavyMLOuHbCynIMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy.vue").then(m => m.default || m),
    children: [
  {
    name: indexRbqeamhCXiMeta?.name ?? "dispecink-sestavy___cs",
    path: indexRbqeamhCXiMeta?.path ?? "",
    meta: indexRbqeamhCXiMeta || {},
    alias: indexRbqeamhCXiMeta?.alias || [],
    redirect: indexRbqeamhCXiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/index.vue").then(m => m.default || m)
  },
  {
    name: material_45udalostiCuov1NMWWJMeta?.name ?? undefined,
    path: material_45udalostiCuov1NMWWJMeta?.path ?? "material-udalosti",
    meta: material_45udalostiCuov1NMWWJMeta || {},
    alias: material_45udalostiCuov1NMWWJMeta?.alias || [],
    redirect: material_45udalostiCuov1NMWWJMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93BDqtHdJNLzMeta?.name ?? "dispecink-sestavy-material-udalosti-id___cs",
    path: _91id_93BDqtHdJNLzMeta?.path ?? ":id()",
    meta: _91id_93BDqtHdJNLzMeta || {},
    alias: _91id_93BDqtHdJNLzMeta?.alias || [],
    redirect: _91id_93BDqtHdJNLzMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti/[id].vue").then(m => m.default || m)
  },
  {
    name: index8iSt0QFkFFMeta?.name ?? "dispecink-sestavy-material-udalosti___cs",
    path: index8iSt0QFkFFMeta?.path ?? "",
    meta: index8iSt0QFkFFMeta || {},
    alias: index8iSt0QFkFFMeta?.alias || [],
    redirect: index8iSt0QFkFFMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti/index.vue").then(m => m.default || m)
  },
  {
    name: s_45vykazemVQdntC0PqkMeta?.name ?? "dispecink-sestavy-material-udalosti-s-vykazem___cs",
    path: s_45vykazemVQdntC0PqkMeta?.path ?? "s-vykazem",
    meta: s_45vykazemVQdntC0PqkMeta || {},
    alias: s_45vykazemVQdntC0PqkMeta?.alias || [],
    redirect: s_45vykazemVQdntC0PqkMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti/s-vykazem.vue").then(m => m.default || m)
  },
  {
    name: se_45sledovanym_45materialemUrrIQn2EcSMeta?.name ?? "dispecink-sestavy-material-udalosti-se-sledovanym-materialem___cs",
    path: se_45sledovanym_45materialemUrrIQn2EcSMeta?.path ?? "se-sledovanym-materialem",
    meta: se_45sledovanym_45materialemUrrIQn2EcSMeta || {},
    alias: se_45sledovanym_45materialemUrrIQn2EcSMeta?.alias || [],
    redirect: se_45sledovanym_45materialemUrrIQn2EcSMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti/se-sledovanym-materialem.vue").then(m => m.default || m)
  },
  {
    name: vsechnyIruB0GRu4EMeta?.name ?? "dispecink-sestavy-material-udalosti-vsechny___cs",
    path: vsechnyIruB0GRu4EMeta?.path ?? "vsechny",
    meta: vsechnyIruB0GRu4EMeta || {},
    alias: vsechnyIruB0GRu4EMeta?.alias || [],
    redirect: vsechnyIruB0GRu4EMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti/vsechny.vue").then(m => m.default || m)
  },
  {
    name: z_45nabidek6capfUBD8ZMeta?.name ?? "dispecink-sestavy-material-udalosti-z-nabidek___cs",
    path: z_45nabidek6capfUBD8ZMeta?.path ?? "z-nabidek",
    meta: z_45nabidek6capfUBD8ZMeta || {},
    alias: z_45nabidek6capfUBD8ZMeta?.alias || [],
    redirect: z_45nabidek6capfUBD8ZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/material-udalosti/z-nabidek.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sledovany_45materialIXK7ZWBOHCMeta?.name ?? undefined,
    path: sledovany_45materialIXK7ZWBOHCMeta?.path ?? "sledovany-material",
    meta: sledovany_45materialIXK7ZWBOHCMeta || {},
    alias: sledovany_45materialIXK7ZWBOHCMeta?.alias || [],
    redirect: sledovany_45materialIXK7ZWBOHCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/sledovany-material.vue").then(m => m.default || m),
    children: [
  {
    name: bez_45planuWn7kCJNRSCMeta?.name ?? "dispecink-sestavy-sledovany-material-bez-planu___cs",
    path: bez_45planuWn7kCJNRSCMeta?.path ?? "bez-planu",
    meta: bez_45planuWn7kCJNRSCMeta || {},
    alias: bez_45planuWn7kCJNRSCMeta?.alias || [],
    redirect: bez_45planuWn7kCJNRSCMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/sledovany-material/bez-planu.vue").then(m => m.default || m)
  },
  {
    name: indexw2pmlXtjmwMeta?.name ?? "dispecink-sestavy-sledovany-material___cs",
    path: indexw2pmlXtjmwMeta?.path ?? "",
    meta: indexw2pmlXtjmwMeta || {},
    alias: indexw2pmlXtjmwMeta?.alias || [],
    redirect: indexw2pmlXtjmwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/sledovany-material/index.vue").then(m => m.default || m)
  },
  {
    name: s_45mnozstvimiZDRG0F5YVMeta?.name ?? "dispecink-sestavy-sledovany-material-s-mnozstvim___cs",
    path: s_45mnozstvimiZDRG0F5YVMeta?.path ?? "s-mnozstvim",
    meta: s_45mnozstvimiZDRG0F5YVMeta || {},
    alias: s_45mnozstvimiZDRG0F5YVMeta?.alias || [],
    redirect: s_45mnozstvimiZDRG0F5YVMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/sledovany-material/s-mnozstvim.vue").then(m => m.default || m)
  },
  {
    name: s_45planemG3D3fqhOk0Meta?.name ?? "dispecink-sestavy-sledovany-material-s-planem___cs",
    path: s_45planemG3D3fqhOk0Meta?.path ?? "s-planem",
    meta: s_45planemG3D3fqhOk0Meta || {},
    alias: s_45planemG3D3fqhOk0Meta?.alias || [],
    redirect: s_45planemG3D3fqhOk0Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/sledovany-material/s-planem.vue").then(m => m.default || m)
  },
  {
    name: vsechenW9y4aUUwf7Meta?.name ?? "dispecink-sestavy-sledovany-material-vsechen___cs",
    path: vsechenW9y4aUUwf7Meta?.path ?? "vsechen",
    meta: vsechenW9y4aUUwf7Meta || {},
    alias: vsechenW9y4aUUwf7Meta?.alias || [],
    redirect: vsechenW9y4aUUwf7Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/sestavy/sledovany-material/vsechen.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: stavy_45skladu4IiUEkoloXMeta?.name ?? undefined,
    path: stavy_45skladu4IiUEkoloXMeta?.path ?? "stavy-skladu",
    meta: stavy_45skladu4IiUEkoloXMeta || {},
    alias: stavy_45skladu4IiUEkoloXMeta?.alias || [],
    redirect: stavy_45skladu4IiUEkoloXMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/stavy-skladu.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93EZ04xzgxicMeta?.name ?? "dispecink-stavy-skladu-id___cs",
    path: _91id_93EZ04xzgxicMeta?.path ?? ":id()",
    meta: _91id_93EZ04xzgxicMeta || {},
    alias: _91id_93EZ04xzgxicMeta?.alias || [],
    redirect: _91id_93EZ04xzgxicMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/stavy-skladu/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYHxf6Fsky9Meta?.name ?? "dispecink-stavy-skladu___cs",
    path: indexYHxf6Fsky9Meta?.path ?? "",
    meta: indexYHxf6Fsky9Meta || {},
    alias: indexYHxf6Fsky9Meta?.alias || [],
    redirect: indexYHxf6Fsky9Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/stavy-skladu/index.vue").then(m => m.default || m)
  },
  {
    name: skladoveoWF0V1HKD9Meta?.name ?? "dispecink-stavy-skladu-skladove___cs",
    path: skladoveoWF0V1HKD9Meta?.path ?? "skladove",
    meta: skladoveoWF0V1HKD9Meta || {},
    alias: skladoveoWF0V1HKD9Meta?.alias || [],
    redirect: skladoveoWF0V1HKD9Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/stavy-skladu/skladove.vue").then(m => m.default || m)
  },
  {
    name: vsechnyfiP67Sw97CMeta?.name ?? "dispecink-stavy-skladu-vsechny___cs",
    path: vsechnyfiP67Sw97CMeta?.path ?? "vsechny",
    meta: vsechnyfiP67Sw97CMeta || {},
    alias: vsechnyfiP67Sw97CMeta?.alias || [],
    redirect: vsechnyfiP67Sw97CMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/stavy-skladu/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: strediskaEhwpF28XXSMeta?.name ?? undefined,
    path: strediskaEhwpF28XXSMeta?.path ?? "strediska",
    meta: strediskaEhwpF28XXSMeta || {},
    alias: strediskaEhwpF28XXSMeta?.alias || [],
    redirect: strediskaEhwpF28XXSMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_933guqagWEhzMeta?.name ?? undefined,
    path: _91id_933guqagWEhzMeta?.path ?? ":id()",
    meta: _91id_933guqagWEhzMeta || {},
    alias: _91id_933guqagWEhzMeta?.alias || [],
    redirect: _91id_933guqagWEhzMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id].vue").then(m => m.default || m),
    children: [
  {
    name: cenikUT2Ie4A5giMeta?.name ?? "dispecink-strediska-id-cenik___cs",
    path: cenikUT2Ie4A5giMeta?.path ?? "cenik",
    meta: cenikUT2Ie4A5giMeta || {},
    alias: cenikUT2Ie4A5giMeta?.alias || [],
    redirect: cenikUT2Ie4A5giMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/cenik.vue").then(m => m.default || m)
  },
  {
    name: dilyR96eHEOycfMeta?.name ?? "dispecink-strediska-id-dily___cs",
    path: dilyR96eHEOycfMeta?.path ?? "dily",
    meta: dilyR96eHEOycfMeta || {},
    alias: dilyR96eHEOycfMeta?.alias || [],
    redirect: dilyR96eHEOycfMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/dily.vue").then(m => m.default || m)
  },
  {
    name: dokumentace8iH1avsZh2Meta?.name ?? "dispecink-strediska-id-dokumentace___cs",
    path: dokumentace8iH1avsZh2Meta?.path ?? "dokumentace",
    meta: dokumentace8iH1avsZh2Meta || {},
    alias: dokumentace8iH1avsZh2Meta?.alias || [],
    redirect: dokumentace8iH1avsZh2Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: hlaseniTDQasWrxC8Meta?.name ?? "dispecink-strediska-id-hlaseni___cs",
    path: hlaseniTDQasWrxC8Meta?.path ?? "hlaseni",
    meta: hlaseniTDQasWrxC8Meta || {},
    alias: hlaseniTDQasWrxC8Meta?.alias || [],
    redirect: hlaseniTDQasWrxC8Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/hlaseni.vue").then(m => m.default || m)
  },
  {
    name: index4nhhh79cGpMeta?.name ?? "dispecink-strediska-id___cs",
    path: index4nhhh79cGpMeta?.path ?? "",
    meta: index4nhhh79cGpMeta || {},
    alias: index4nhhh79cGpMeta?.alias || [],
    redirect: index4nhhh79cGpMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: lokalitygZnoT47iErMeta?.name ?? "dispecink-strediska-id-lokality___cs",
    path: lokalitygZnoT47iErMeta?.path ?? "lokality",
    meta: lokalitygZnoT47iErMeta || {},
    alias: lokalitygZnoT47iErMeta?.alias || [],
    redirect: lokalitygZnoT47iErMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/lokality.vue").then(m => m.default || m)
  },
  {
    name: techniciQWIStPRmaSMeta?.name ?? undefined,
    path: techniciQWIStPRmaSMeta?.path ?? "technici",
    meta: techniciQWIStPRmaSMeta || {},
    alias: techniciQWIStPRmaSMeta?.alias || [],
    redirect: techniciQWIStPRmaSMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/technici.vue").then(m => m.default || m),
    children: [
  {
    name: aktivniZY1Z3uEfV6Meta?.name ?? "dispecink-strediska-id-technici-aktivni___cs",
    path: aktivniZY1Z3uEfV6Meta?.path ?? "aktivni",
    meta: aktivniZY1Z3uEfV6Meta || {},
    alias: aktivniZY1Z3uEfV6Meta?.alias || [],
    redirect: aktivniZY1Z3uEfV6Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/technici/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexcVkale5EhzMeta?.name ?? "dispecink-strediska-id-technici___cs",
    path: indexcVkale5EhzMeta?.path ?? "",
    meta: indexcVkale5EhzMeta || {},
    alias: indexcVkale5EhzMeta?.alias || [],
    redirect: indexcVkale5EhzMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/technici/index.vue").then(m => m.default || m)
  },
  {
    name: neaktivni5a7qaYB5XkMeta?.name ?? "dispecink-strediska-id-technici-neaktivni___cs",
    path: neaktivni5a7qaYB5XkMeta?.path ?? "neaktivni",
    meta: neaktivni5a7qaYB5XkMeta || {},
    alias: neaktivni5a7qaYB5XkMeta?.alias || [],
    redirect: neaktivni5a7qaYB5XkMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/technici/neaktivni.vue").then(m => m.default || m)
  }
]
  },
  {
    name: udalosti0J3ET2XV3xMeta?.name ?? undefined,
    path: udalosti0J3ET2XV3xMeta?.path ?? "udalosti",
    meta: udalosti0J3ET2XV3xMeta || {},
    alias: udalosti0J3ET2XV3xMeta?.alias || [],
    redirect: udalosti0J3ET2XV3xMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti.vue").then(m => m.default || m),
    children: [
  {
    name: aktivniAl8j4dlRBpMeta?.name ?? "dispecink-strediska-id-udalosti-aktivni___cs",
    path: aktivniAl8j4dlRBpMeta?.path ?? "aktivni",
    meta: aktivniAl8j4dlRBpMeta || {},
    alias: aktivniAl8j4dlRBpMeta?.alias || [],
    redirect: aktivniAl8j4dlRBpMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/aktivni.vue").then(m => m.default || m)
  },
  {
    name: index4xAgQj4X9VMeta?.name ?? "dispecink-strediska-id-udalosti___cs",
    path: index4xAgQj4X9VMeta?.path ?? "",
    meta: index4xAgQj4X9VMeta || {},
    alias: index4xAgQj4X9VMeta?.alias || [],
    redirect: index4xAgQj4X9VMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/index.vue").then(m => m.default || m)
  },
  {
    name: noveIWzJ4WMES5Meta?.name ?? "dispecink-strediska-id-udalosti-nove___cs",
    path: noveIWzJ4WMES5Meta?.path ?? "nove",
    meta: noveIWzJ4WMES5Meta || {},
    alias: noveIWzJ4WMES5Meta?.alias || [],
    redirect: noveIWzJ4WMES5Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/nove.vue").then(m => m.default || m)
  },
  {
    name: potvrzeni_45vstupuCGadOzhOUfMeta?.name ?? "dispecink-strediska-id-udalosti-potvrzeni-vstupu___cs",
    path: potvrzeni_45vstupuCGadOzhOUfMeta?.path ?? "potvrzeni-vstupu",
    meta: potvrzeni_45vstupuCGadOzhOUfMeta || {},
    alias: potvrzeni_45vstupuCGadOzhOUfMeta?.alias || [],
    redirect: potvrzeni_45vstupuCGadOzhOUfMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/potvrzeni-vstupu.vue").then(m => m.default || m)
  },
  {
    name: uzavrenecTBMjeIz9dMeta?.name ?? "dispecink-strediska-id-udalosti-uzavrene___cs",
    path: uzavrenecTBMjeIz9dMeta?.path ?? "uzavrene",
    meta: uzavrenecTBMjeIz9dMeta || {},
    alias: uzavrenecTBMjeIz9dMeta?.alias || [],
    redirect: uzavrenecTBMjeIz9dMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/uzavrene.vue").then(m => m.default || m)
  },
  {
    name: vsechny7oXdo2NOhMMeta?.name ?? "dispecink-strediska-id-udalosti-vsechny___cs",
    path: vsechny7oXdo2NOhMMeta?.path ?? "vsechny",
    meta: vsechny7oXdo2NOhMMeta || {},
    alias: vsechny7oXdo2NOhMMeta?.alias || [],
    redirect: vsechny7oXdo2NOhMMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/vsechny.vue").then(m => m.default || m)
  },
  {
    name: zadosti_45o_45vstupt6bR6CuczkMeta?.name ?? "dispecink-strediska-id-udalosti-zadosti-o-vstup___cs",
    path: zadosti_45o_45vstupt6bR6CuczkMeta?.path ?? "zadosti-o-vstup",
    meta: zadosti_45o_45vstupt6bR6CuczkMeta || {},
    alias: zadosti_45o_45vstupt6bR6CuczkMeta?.alias || [],
    redirect: zadosti_45o_45vstupt6bR6CuczkMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/udalosti/zadosti-o-vstup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: vykazya710PP76RZMeta?.name ?? "dispecink-strediska-id-vykazy___cs",
    path: vykazya710PP76RZMeta?.path ?? "vykazy",
    meta: vykazya710PP76RZMeta || {},
    alias: vykazya710PP76RZMeta?.alias || [],
    redirect: vykazya710PP76RZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/[id]/vykazy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: aktivni6EeiSGa6RiMeta?.name ?? "dispecink-strediska-aktivni___cs",
    path: aktivni6EeiSGa6RiMeta?.path ?? "aktivni",
    meta: aktivni6EeiSGa6RiMeta || {},
    alias: aktivni6EeiSGa6RiMeta?.alias || [],
    redirect: aktivni6EeiSGa6RiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexSXmF0hcmqjMeta?.name ?? "dispecink-strediska___cs",
    path: indexSXmF0hcmqjMeta?.path ?? "",
    meta: indexSXmF0hcmqjMeta || {},
    alias: indexSXmF0hcmqjMeta?.alias || [],
    redirect: indexSXmF0hcmqjMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/index.vue").then(m => m.default || m)
  },
  {
    name: neaktivniDTPn05AlVWMeta?.name ?? "dispecink-strediska-neaktivni___cs",
    path: neaktivniDTPn05AlVWMeta?.path ?? "neaktivni",
    meta: neaktivniDTPn05AlVWMeta || {},
    alias: neaktivniDTPn05AlVWMeta?.alias || [],
    redirect: neaktivniDTPn05AlVWMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/strediska/neaktivni.vue").then(m => m.default || m)
  }
]
  },
  {
    name: typy_45diluuqthPraSYFMeta?.name ?? undefined,
    path: typy_45diluuqthPraSYFMeta?.path ?? "typy-dilu",
    meta: typy_45diluuqthPraSYFMeta || {},
    alias: typy_45diluuqthPraSYFMeta?.alias || [],
    redirect: typy_45diluuqthPraSYFMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9386x2iSo7LtMeta?.name ?? undefined,
    path: _91id_9386x2iSo7LtMeta?.path ?? ":id()",
    meta: _91id_9386x2iSo7LtMeta || {},
    alias: _91id_9386x2iSo7LtMeta?.alias || [],
    redirect: _91id_9386x2iSo7LtMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/[id].vue").then(m => m.default || m),
    children: [
  {
    name: demontovaneALIHnUGA3CMeta?.name ?? "dispecink-typy-dilu-id-demontovane___cs",
    path: demontovaneALIHnUGA3CMeta?.path ?? "demontovane",
    meta: demontovaneALIHnUGA3CMeta || {},
    alias: demontovaneALIHnUGA3CMeta?.alias || [],
    redirect: demontovaneALIHnUGA3CMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/[id]/demontovane.vue").then(m => m.default || m)
  },
  {
    name: index9qZgZ9yGJtMeta?.name ?? "dispecink-typy-dilu-id___cs",
    path: index9qZgZ9yGJtMeta?.path ?? "",
    meta: index9qZgZ9yGJtMeta || {},
    alias: index9qZgZ9yGJtMeta?.alias || [],
    redirect: index9qZgZ9yGJtMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: instalovaneI2UpP9iBshMeta?.name ?? "dispecink-typy-dilu-id-instalovane___cs",
    path: instalovaneI2UpP9iBshMeta?.path ?? "instalovane",
    meta: instalovaneI2UpP9iBshMeta || {},
    alias: instalovaneI2UpP9iBshMeta?.alias || [],
    redirect: instalovaneI2UpP9iBshMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/[id]/instalovane.vue").then(m => m.default || m)
  },
  {
    name: nahradni_45dilyp09bKHYW8IMeta?.name ?? "dispecink-typy-dilu-id-nahradni-dily___cs",
    path: nahradni_45dilyp09bKHYW8IMeta?.path ?? "nahradni-dily",
    meta: nahradni_45dilyp09bKHYW8IMeta || {},
    alias: nahradni_45dilyp09bKHYW8IMeta?.alias || [],
    redirect: nahradni_45dilyp09bKHYW8IMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/[id]/nahradni-dily.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexcUFmUl4iTJMeta?.name ?? "dispecink-typy-dilu___cs",
    path: indexcUFmUl4iTJMeta?.path ?? "",
    meta: indexcUFmUl4iTJMeta || {},
    alias: indexcUFmUl4iTJMeta?.alias || [],
    redirect: indexcUFmUl4iTJMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/index.vue").then(m => m.default || m)
  },
  {
    name: pripraveneJGkZGszCKWMeta?.name ?? "dispecink-typy-dilu-pripravene___cs",
    path: pripraveneJGkZGszCKWMeta?.path ?? "pripravene",
    meta: pripraveneJGkZGszCKWMeta || {},
    alias: pripraveneJGkZGszCKWMeta?.alias || [],
    redirect: pripraveneJGkZGszCKWMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/pripravene.vue").then(m => m.default || m)
  },
  {
    name: vyrazeneZHW5DqpjgZMeta?.name ?? "dispecink-typy-dilu-vyrazene___cs",
    path: vyrazeneZHW5DqpjgZMeta?.path ?? "vyrazene",
    meta: vyrazeneZHW5DqpjgZMeta || {},
    alias: vyrazeneZHW5DqpjgZMeta?.alias || [],
    redirect: vyrazeneZHW5DqpjgZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/typy-dilu/vyrazene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: udalostiYSxbIhn7g3Meta?.name ?? undefined,
    path: udalostiYSxbIhn7g3Meta?.path ?? "udalosti",
    meta: udalostiYSxbIhn7g3Meta || {},
    alias: udalostiYSxbIhn7g3Meta?.alias || [],
    redirect: udalostiYSxbIhn7g3Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93rXhB6PaHwAMeta?.name ?? undefined,
    path: _91id_93rXhB6PaHwAMeta?.path ?? ":id()",
    meta: _91id_93rXhB6PaHwAMeta || {},
    alias: _91id_93rXhB6PaHwAMeta?.alias || [],
    redirect: _91id_93rXhB6PaHwAMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id].vue").then(m => m.default || m),
    children: [
  {
    name: dokumentaceLBOXJfbDtQMeta?.name ?? "dispecink-udalosti-id-dokumentace___cs",
    path: dokumentaceLBOXJfbDtQMeta?.path ?? "dokumentace",
    meta: dokumentaceLBOXJfbDtQMeta || {},
    alias: dokumentaceLBOXJfbDtQMeta?.alias || [],
    redirect: dokumentaceLBOXJfbDtQMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: hlasenisArMb12LBLMeta?.name ?? undefined,
    path: hlasenisArMb12LBLMeta?.path ?? "hlaseni",
    meta: hlasenisArMb12LBLMeta || {},
    alias: hlasenisArMb12LBLMeta?.alias || [],
    redirect: hlasenisArMb12LBLMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/hlaseni.vue").then(m => m.default || m),
    children: [
  {
    name: indexcMad59qe2kMeta?.name ?? "dispecink-udalosti-id-hlaseni___cs",
    path: indexcMad59qe2kMeta?.path ?? "",
    meta: indexcMad59qe2kMeta || {},
    alias: indexcMad59qe2kMeta?.alias || [],
    redirect: indexcMad59qe2kMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/hlaseni/index.vue").then(m => m.default || m)
  },
  {
    name: k_45dilum_45udalosti9ONHScEKyHMeta?.name ?? "dispecink-udalosti-id-hlaseni-k-dilum-udalosti___cs",
    path: k_45dilum_45udalosti9ONHScEKyHMeta?.path ?? "k-dilum-udalosti",
    meta: k_45dilum_45udalosti9ONHScEKyHMeta || {},
    alias: k_45dilum_45udalosti9ONHScEKyHMeta?.alias || [],
    redirect: k_45dilum_45udalosti9ONHScEKyHMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/hlaseni/k-dilum-udalosti.vue").then(m => m.default || m)
  },
  {
    name: vsechnapyuMZSexIxMeta?.name ?? "dispecink-udalosti-id-hlaseni-vsechna___cs",
    path: vsechnapyuMZSexIxMeta?.path ?? "vsechna",
    meta: vsechnapyuMZSexIxMeta || {},
    alias: vsechnapyuMZSexIxMeta?.alias || [],
    redirect: vsechnapyuMZSexIxMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/hlaseni/vsechna.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKSqr7BpCy4Meta?.name ?? "dispecink-udalosti-id___cs",
    path: indexKSqr7BpCy4Meta?.path ?? "",
    meta: indexKSqr7BpCy4Meta || {},
    alias: indexKSqr7BpCy4Meta?.alias || [],
    redirect: indexKSqr7BpCy4Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: materialeMw71SyDTtMeta?.name ?? "dispecink-udalosti-id-material___cs",
    path: materialeMw71SyDTtMeta?.path ?? "material",
    meta: materialeMw71SyDTtMeta || {},
    alias: materialeMw71SyDTtMeta?.alias || [],
    redirect: materialeMw71SyDTtMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/material.vue").then(m => m.default || m)
  },
  {
    name: nabidkyn50hHZhxYeMeta?.name ?? "dispecink-udalosti-id-nabidky___cs",
    path: nabidkyn50hHZhxYeMeta?.path ?? "nabidky",
    meta: nabidkyn50hHZhxYeMeta || {},
    alias: nabidkyn50hHZhxYeMeta?.alias || [],
    redirect: nabidkyn50hHZhxYeMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/nabidky.vue").then(m => m.default || m)
  },
  {
    name: nemoSxBaJqq1QlMeta?.name ?? "dispecink-udalosti-id-nemo___cs",
    path: nemoSxBaJqq1QlMeta?.path ?? "nemo",
    meta: nemoSxBaJqq1QlMeta || {},
    alias: nemoSxBaJqq1QlMeta?.alias || [],
    redirect: nemoSxBaJqq1QlMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/nemo.vue").then(m => m.default || m),
    children: [
  {
    name: nezpracovaneFH2YlCJJFhMeta?.name ?? "dispecink-udalosti-id-nemo-nezpracovane___cs",
    path: nezpracovaneFH2YlCJJFhMeta?.path ?? "nezpracovane",
    meta: nezpracovaneFH2YlCJJFhMeta || {},
    alias: nezpracovaneFH2YlCJJFhMeta?.alias || [],
    redirect: nezpracovaneFH2YlCJJFhMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/nemo/nezpracovane.vue").then(m => m.default || m)
  },
  {
    name: vse9T1f2hMly2Meta?.name ?? "dispecink-udalosti-id-nemo-vse___cs",
    path: vse9T1f2hMly2Meta?.path ?? "vse",
    meta: vse9T1f2hMly2Meta || {},
    alias: vse9T1f2hMly2Meta?.alias || [],
    redirect: vse9T1f2hMly2Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/nemo/vse.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sledovany_45materialiwrwLEKLiZMeta?.name ?? "dispecink-udalosti-id-sledovany-material___cs",
    path: sledovany_45materialiwrwLEKLiZMeta?.path ?? "sledovany-material",
    meta: sledovany_45materialiwrwLEKLiZMeta || {},
    alias: sledovany_45materialiwrwLEKLiZMeta?.alias || [],
    redirect: sledovany_45materialiwrwLEKLiZMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/sledovany-material.vue").then(m => m.default || m)
  },
  {
    name: ukolyEpbaMERqG0Meta?.name ?? "dispecink-udalosti-id-ukoly___cs",
    path: ukolyEpbaMERqG0Meta?.path ?? "ukoly",
    meta: ukolyEpbaMERqG0Meta || {},
    alias: ukolyEpbaMERqG0Meta?.alias || [],
    redirect: ukolyEpbaMERqG0Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/[id]/ukoly.vue").then(m => m.default || m)
  }
]
  },
  {
    name: aktivniMG3o4CoeI7Meta?.name ?? "dispecink-udalosti-aktivni___cs",
    path: aktivniMG3o4CoeI7Meta?.path ?? "aktivni",
    meta: aktivniMG3o4CoeI7Meta || {},
    alias: aktivniMG3o4CoeI7Meta?.alias || [],
    redirect: aktivniMG3o4CoeI7Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/aktivni.vue").then(m => m.default || m)
  },
  {
    name: fakturovatbCREmZ3D2NMeta?.name ?? "dispecink-udalosti-fakturovat___cs",
    path: fakturovatbCREmZ3D2NMeta?.path ?? "fakturovat",
    meta: fakturovatbCREmZ3D2NMeta || {},
    alias: fakturovatbCREmZ3D2NMeta?.alias || [],
    redirect: fakturovatbCREmZ3D2NMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/fakturovat.vue").then(m => m.default || m)
  },
  {
    name: indexWYnm2AIe95Meta?.name ?? "dispecink-udalosti___cs",
    path: indexWYnm2AIe95Meta?.path ?? "",
    meta: indexWYnm2AIe95Meta || {},
    alias: indexWYnm2AIe95Meta?.alias || [],
    redirect: indexWYnm2AIe95Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/index.vue").then(m => m.default || m)
  },
  {
    name: uzavreneFCUaADLIVNMeta?.name ?? "dispecink-udalosti-uzavrene___cs",
    path: uzavreneFCUaADLIVNMeta?.path ?? "uzavrene",
    meta: uzavreneFCUaADLIVNMeta || {},
    alias: uzavreneFCUaADLIVNMeta?.alias || [],
    redirect: uzavreneFCUaADLIVNMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/uzavrene.vue").then(m => m.default || m)
  },
  {
    name: vseNfxPIaTllGMeta?.name ?? "dispecink-udalosti-vse___cs",
    path: vseNfxPIaTllGMeta?.path ?? "vse",
    meta: vseNfxPIaTllGMeta || {},
    alias: vseNfxPIaTllGMeta?.alias || [],
    redirect: vseNfxPIaTllGMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/vse.vue").then(m => m.default || m)
  },
  {
    name: zpracovatMuvQP3foYqMeta?.name ?? "dispecink-udalosti-zpracovat___cs",
    path: zpracovatMuvQP3foYqMeta?.path ?? "zpracovat",
    meta: zpracovatMuvQP3foYqMeta || {},
    alias: zpracovatMuvQP3foYqMeta?.alias || [],
    redirect: zpracovatMuvQP3foYqMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/zpracovat.vue").then(m => m.default || m)
  },
  {
    name: zruseneGZMPcMF3aNMeta?.name ?? "dispecink-udalosti-zrusene___cs",
    path: zruseneGZMPcMF3aNMeta?.path ?? "zrusene",
    meta: zruseneGZMPcMF3aNMeta || {},
    alias: zruseneGZMPcMF3aNMeta?.alias || [],
    redirect: zruseneGZMPcMF3aNMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/udalosti/zrusene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: ukolyQfrMIhx6RpMeta?.name ?? undefined,
    path: ukolyQfrMIhx6RpMeta?.path ?? "ukoly",
    meta: ukolyQfrMIhx6RpMeta || {},
    alias: ukolyQfrMIhx6RpMeta?.alias || [],
    redirect: ukolyQfrMIhx6RpMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/ukoly.vue").then(m => m.default || m),
    children: [
  {
    name: alarmyOQwGrkZi7tMeta?.name ?? "dispecink-ukoly-alarmy___cs",
    path: alarmyOQwGrkZi7tMeta?.path ?? "alarmy",
    meta: alarmyOQwGrkZi7tMeta || {},
    alias: alarmyOQwGrkZi7tMeta?.alias || [],
    redirect: alarmyOQwGrkZi7tMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/ukoly/alarmy.vue").then(m => m.default || m)
  },
  {
    name: indexgpCQLzddyTMeta?.name ?? "dispecink-ukoly___cs",
    path: indexgpCQLzddyTMeta?.path ?? "",
    meta: indexgpCQLzddyTMeta || {},
    alias: indexgpCQLzddyTMeta?.alias || [],
    redirect: indexgpCQLzddyTMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/ukoly/index.vue").then(m => m.default || m)
  },
  {
    name: pripraveneBjPAaD7IPqMeta?.name ?? "dispecink-ukoly-pripravene___cs",
    path: pripraveneBjPAaD7IPqMeta?.path ?? "pripravene",
    meta: pripraveneBjPAaD7IPqMeta || {},
    alias: pripraveneBjPAaD7IPqMeta?.alias || [],
    redirect: pripraveneBjPAaD7IPqMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/ukoly/pripravene.vue").then(m => m.default || m)
  },
  {
    name: vsechnyuxkJdZ2X09Meta?.name ?? "dispecink-ukoly-vsechny___cs",
    path: vsechnyuxkJdZ2X09Meta?.path ?? "vsechny",
    meta: vsechnyuxkJdZ2X09Meta || {},
    alias: vsechnyuxkJdZ2X09Meta?.alias || [],
    redirect: vsechnyuxkJdZ2X09Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/ukoly/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: uzivateleDSmjWiNew0Meta?.name ?? undefined,
    path: uzivateleDSmjWiNew0Meta?.path ?? "uzivatele",
    meta: uzivateleDSmjWiNew0Meta || {},
    alias: uzivateleDSmjWiNew0Meta?.alias || [],
    redirect: uzivateleDSmjWiNew0Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele.vue").then(m => m.default || m),
    children: [
  {
    name: aplikacelZhInbZRhdMeta?.name ?? "dispecink-uzivatele-aplikace___cs",
    path: aplikacelZhInbZRhdMeta?.path ?? "aplikace",
    meta: aplikacelZhInbZRhdMeta || {},
    alias: aplikacelZhInbZRhdMeta?.alias || [],
    redirect: aplikacelZhInbZRhdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/aplikace.vue").then(m => m.default || m)
  },
  {
    name: indexc0GqvCU0KJMeta?.name ?? "dispecink-uzivatele___cs",
    path: indexc0GqvCU0KJMeta?.path ?? "",
    meta: indexc0GqvCU0KJMeta || {},
    alias: indexc0GqvCU0KJMeta?.alias || [],
    redirect: indexc0GqvCU0KJMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/index.vue").then(m => m.default || m)
  },
  {
    name: skupiny9S17M7Azk1Meta?.name ?? "dispecink-uzivatele-skupiny___cs",
    path: skupiny9S17M7Azk1Meta?.path ?? "skupiny",
    meta: skupiny9S17M7Azk1Meta || {},
    alias: skupiny9S17M7Azk1Meta?.alias || [],
    redirect: skupiny9S17M7Azk1Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/skupiny.vue").then(m => m.default || m)
  },
  {
    name: uzivateleMwU6RsUR0TMeta?.name ?? undefined,
    path: uzivateleMwU6RsUR0TMeta?.path ?? "uzivatele",
    meta: uzivateleMwU6RsUR0TMeta || {},
    alias: uzivateleMwU6RsUR0TMeta?.alias || [],
    redirect: uzivateleMwU6RsUR0TMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/uzivatele.vue").then(m => m.default || m),
    children: [
  {
    name: aktivni3rjj5qQWixMeta?.name ?? "dispecink-uzivatele-uzivatele-aktivni___cs",
    path: aktivni3rjj5qQWixMeta?.path ?? "aktivni",
    meta: aktivni3rjj5qQWixMeta || {},
    alias: aktivni3rjj5qQWixMeta?.alias || [],
    redirect: aktivni3rjj5qQWixMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/uzivatele/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexk715s6DlFIMeta?.name ?? "dispecink-uzivatele-uzivatele___cs",
    path: indexk715s6DlFIMeta?.path ?? "",
    meta: indexk715s6DlFIMeta || {},
    alias: indexk715s6DlFIMeta?.alias || [],
    redirect: indexk715s6DlFIMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/uzivatele/index.vue").then(m => m.default || m)
  },
  {
    name: neaktivnia6NMAiAiQQMeta?.name ?? "dispecink-uzivatele-uzivatele-neaktivni___cs",
    path: neaktivnia6NMAiAiQQMeta?.path ?? "neaktivni",
    meta: neaktivnia6NMAiAiQQMeta || {},
    alias: neaktivnia6NMAiAiQQMeta?.alias || [],
    redirect: neaktivnia6NMAiAiQQMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/uzivatele/neaktivni.vue").then(m => m.default || m)
  },
  {
    name: vsektwppiAkJQMeta?.name ?? "dispecink-uzivatele-uzivatele-vse___cs",
    path: vsektwppiAkJQMeta?.path ?? "vse",
    meta: vsektwppiAkJQMeta || {},
    alias: vsektwppiAkJQMeta?.alias || [],
    redirect: vsektwppiAkJQMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/uzivatele/uzivatele/vse.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: vykazy5Lth0EAEaPMeta?.name ?? undefined,
    path: vykazy5Lth0EAEaPMeta?.path ?? "vykazy",
    meta: vykazy5Lth0EAEaPMeta || {},
    alias: vykazy5Lth0EAEaPMeta?.alias || [],
    redirect: vykazy5Lth0EAEaPMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vykazy.vue").then(m => m.default || m),
    children: [
  {
    name: index0pPVYnaOLiMeta?.name ?? "dispecink-vykazy___cs",
    path: index0pPVYnaOLiMeta?.path ?? "",
    meta: index0pPVYnaOLiMeta || {},
    alias: index0pPVYnaOLiMeta?.alias || [],
    redirect: index0pPVYnaOLiMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vykazy/index.vue").then(m => m.default || m)
  },
  {
    name: k_45fakturaciX9rhyI1712Meta?.name ?? "dispecink-vykazy-k-fakturaci___cs",
    path: k_45fakturaciX9rhyI1712Meta?.path ?? "k-fakturaci",
    meta: k_45fakturaciX9rhyI1712Meta || {},
    alias: k_45fakturaciX9rhyI1712Meta?.alias || [],
    redirect: k_45fakturaciX9rhyI1712Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/vykazy/k-fakturaci.vue").then(m => m.default || m)
  },
  {
    name: vsechnyUGvI1lPGtYMeta?.name ?? "dispecink-vykazy-vsechny___cs",
    path: vsechnyUGvI1lPGtYMeta?.path ?? "vsechny",
    meta: vsechnyUGvI1lPGtYMeta || {},
    alias: vsechnyUGvI1lPGtYMeta?.alias || [],
    redirect: vsechnyUGvI1lPGtYMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vykazy/vsechny.vue").then(m => m.default || m)
  }
]
  },
  {
    name: vzkazyKPYhk8U18wMeta?.name ?? undefined,
    path: vzkazyKPYhk8U18wMeta?.path ?? "vzkazy",
    meta: vzkazyKPYhk8U18wMeta || {},
    alias: vzkazyKPYhk8U18wMeta?.alias || [],
    redirect: vzkazyKPYhk8U18wMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93RbnbJVfEfeMeta?.name ?? "dispecink-vzkazy-id___cs",
    path: _91id_93RbnbJVfEfeMeta?.path ?? ":id()",
    meta: _91id_93RbnbJVfEfeMeta || {},
    alias: _91id_93RbnbJVfEfeMeta?.alias || [],
    redirect: _91id_93RbnbJVfEfeMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/[id].vue").then(m => m.default || m)
  },
  {
    name: indexioBVqFaUWhMeta?.name ?? "dispecink-vzkazy___cs",
    path: indexioBVqFaUWhMeta?.path ?? "",
    meta: indexioBVqFaUWhMeta || {},
    alias: indexioBVqFaUWhMeta?.alias || [],
    redirect: indexioBVqFaUWhMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/index.vue").then(m => m.default || m)
  },
  {
    name: k_45ukonceni8ePyzXmDn6Meta?.name ?? "dispecink-vzkazy-k-ukonceni___cs",
    path: k_45ukonceni8ePyzXmDn6Meta?.path ?? "k-ukonceni",
    meta: k_45ukonceni8ePyzXmDn6Meta || {},
    alias: k_45ukonceni8ePyzXmDn6Meta?.alias || [],
    redirect: k_45ukonceni8ePyzXmDn6Meta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/k-ukonceni.vue").then(m => m.default || m)
  },
  {
    name: pripravene2gx2w3OTatMeta?.name ?? "dispecink-vzkazy-pripravene___cs",
    path: pripravene2gx2w3OTatMeta?.path ?? "pripravene",
    meta: pripravene2gx2w3OTatMeta || {},
    alias: pripravene2gx2w3OTatMeta?.alias || [],
    redirect: pripravene2gx2w3OTatMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/pripravene.vue").then(m => m.default || m)
  },
  {
    name: pro_45mepEqb7aeeJtMeta?.name ?? "dispecink-vzkazy-pro-me___cs",
    path: pro_45mepEqb7aeeJtMeta?.path ?? "pro-me",
    meta: pro_45mepEqb7aeeJtMeta || {},
    alias: pro_45mepEqb7aeeJtMeta?.alias || [],
    redirect: pro_45mepEqb7aeeJtMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/pro-me.vue").then(m => m.default || m)
  },
  {
    name: ukoncene0R2ApUDe1HMeta?.name ?? "dispecink-vzkazy-ukoncene___cs",
    path: ukoncene0R2ApUDe1HMeta?.path ?? "ukoncene",
    meta: ukoncene0R2ApUDe1HMeta || {},
    alias: ukoncene0R2ApUDe1HMeta?.alias || [],
    redirect: ukoncene0R2ApUDe1HMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/ukoncene.vue").then(m => m.default || m)
  },
  {
    name: vlastnieD1nruy3AgMeta?.name ?? "dispecink-vzkazy-vlastni___cs",
    path: vlastnieD1nruy3AgMeta?.path ?? "vlastni",
    meta: vlastnieD1nruy3AgMeta || {},
    alias: vlastnieD1nruy3AgMeta?.alias || [],
    redirect: vlastnieD1nruy3AgMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/vlastni.vue").then(m => m.default || m)
  },
  {
    name: vsechnykfke1pcMmdMeta?.name ?? "dispecink-vzkazy-vsechny___cs",
    path: vsechnykfke1pcMmdMeta?.path ?? "vsechny",
    meta: vsechnykfke1pcMmdMeta || {},
    alias: vsechnykfke1pcMmdMeta?.alias || [],
    redirect: vsechnykfke1pcMmdMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/vsechny.vue").then(m => m.default || m)
  },
  {
    name: zobrazeneqbMSzwiYnYMeta?.name ?? "dispecink-vzkazy-zobrazene___cs",
    path: zobrazeneqbMSzwiYnYMeta?.path ?? "zobrazene",
    meta: zobrazeneqbMSzwiYnYMeta || {},
    alias: zobrazeneqbMSzwiYnYMeta?.alias || [],
    redirect: zobrazeneqbMSzwiYnYMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzkazy/zobrazene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: vzory_45textu73em1AHXnaMeta?.name ?? undefined,
    path: vzory_45textu73em1AHXnaMeta?.path ?? "vzory-textu",
    meta: vzory_45textu73em1AHXnaMeta || {},
    alias: vzory_45textu73em1AHXnaMeta?.alias || [],
    redirect: vzory_45textu73em1AHXnaMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzory-textu.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xU3DUHZZ1jMeta?.name ?? "dispecink-vzory-textu-id___cs",
    path: _91id_93xU3DUHZZ1jMeta?.path ?? ":id()",
    meta: _91id_93xU3DUHZZ1jMeta || {},
    alias: _91id_93xU3DUHZZ1jMeta?.alias || [],
    redirect: _91id_93xU3DUHZZ1jMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzory-textu/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvFiUntSD1PMeta?.name ?? "dispecink-vzory-textu___cs",
    path: indexvFiUntSD1PMeta?.path ?? "",
    meta: indexvFiUntSD1PMeta || {},
    alias: indexvFiUntSD1PMeta?.alias || [],
    redirect: indexvFiUntSD1PMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/vzory-textu/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: zastupyNsOMrNiuMwMeta?.name ?? "dispecink-zastupy___cs",
    path: zastupyNsOMrNiuMwMeta?.path ?? "zastupy",
    meta: zastupyNsOMrNiuMwMeta || {},
    alias: zastupyNsOMrNiuMwMeta?.alias || [],
    redirect: zastupyNsOMrNiuMwMeta?.redirect,
    component: () => import("/app/src/pages/dispecink/zastupy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___cs",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login3hUYZUGxmUMeta?.name ?? "login___cs",
    path: login3hUYZUGxmUMeta?.path ?? "/login",
    meta: login3hUYZUGxmUMeta || {},
    alias: login3hUYZUGxmUMeta?.alias || [],
    redirect: login3hUYZUGxmUMeta?.redirect,
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mapawcAirIz6RzMeta?.name ?? undefined,
    path: mapawcAirIz6RzMeta?.path ?? "/mapa",
    meta: mapawcAirIz6RzMeta || {},
    alias: mapawcAirIz6RzMeta?.alias || [],
    redirect: mapawcAirIz6RzMeta?.redirect,
    component: () => import("/app/src/pages/mapa.vue").then(m => m.default || m),
    children: [
  {
    name: indexZ1C5ZYjCWBMeta?.name ?? "mapa___cs",
    path: indexZ1C5ZYjCWBMeta?.path ?? "",
    meta: indexZ1C5ZYjCWBMeta || {},
    alias: indexZ1C5ZYjCWBMeta?.alias || [],
    redirect: indexZ1C5ZYjCWBMeta?.redirect,
    component: () => import("/app/src/pages/mapa/index.vue").then(m => m.default || m)
  },
  {
    name: lokalityH84hmzvUP6Meta?.name ?? "mapa-lokality___cs",
    path: lokalityH84hmzvUP6Meta?.path ?? "lokality",
    meta: lokalityH84hmzvUP6Meta || {},
    alias: lokalityH84hmzvUP6Meta?.alias || [],
    redirect: lokalityH84hmzvUP6Meta?.redirect,
    component: () => import("/app/src/pages/mapa/lokality.vue").then(m => m.default || m)
  },
  {
    name: udalostiw4LfvfzGNMMeta?.name ?? "mapa-udalosti___cs",
    path: udalostiw4LfvfzGNMMeta?.path ?? "udalosti",
    meta: udalostiw4LfvfzGNMMeta || {},
    alias: udalostiw4LfvfzGNMMeta?.alias || [],
    redirect: udalostiw4LfvfzGNMMeta?.redirect,
    component: () => import("/app/src/pages/mapa/udalosti.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPTOP8vOjEzMeta?.name ?? "obchod___cs",
    path: indexPTOP8vOjEzMeta?.path ?? "/obchod",
    meta: indexPTOP8vOjEzMeta || {},
    alias: indexPTOP8vOjEzMeta?.alias || [],
    redirect: indexPTOP8vOjEzMeta?.redirect,
    component: () => import("/app/src/pages/obchod/index.vue").then(m => m.default || m)
  },
  {
    name: profileTx35vCKuH1Meta?.name ?? undefined,
    path: profileTx35vCKuH1Meta?.path ?? "/profile",
    meta: profileTx35vCKuH1Meta || {},
    alias: profileTx35vCKuH1Meta?.alias || [],
    redirect: profileTx35vCKuH1Meta?.redirect,
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: indexSJNeMkG0qzMeta?.name ?? "profile___cs",
    path: indexSJNeMkG0qzMeta?.path ?? "",
    meta: indexSJNeMkG0qzMeta || {},
    alias: indexSJNeMkG0qzMeta?.alias || [],
    redirect: indexSJNeMkG0qzMeta?.redirect,
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: servisc5h08Gx8qSMeta?.name ?? undefined,
    path: servisc5h08Gx8qSMeta?.path ?? "/servis",
    meta: servisc5h08Gx8qSMeta || {},
    alias: servisc5h08Gx8qSMeta?.alias || [],
    redirect: servisc5h08Gx8qSMeta?.redirect,
    component: () => import("/app/src/pages/servis.vue").then(m => m.default || m),
    children: [
  {
    name: absenceDX9fC6aCJlMeta?.name ?? undefined,
    path: absenceDX9fC6aCJlMeta?.path ?? "absence",
    meta: absenceDX9fC6aCJlMeta || {},
    alias: absenceDX9fC6aCJlMeta?.alias || [],
    redirect: absenceDX9fC6aCJlMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence.vue").then(m => m.default || m),
    children: [
  {
    name: indexrrXYqk3dLfMeta?.name ?? "servis-absence___cs",
    path: indexrrXYqk3dLfMeta?.path ?? "",
    meta: indexrrXYqk3dLfMeta || {},
    alias: indexrrXYqk3dLfMeta?.alias || [],
    redirect: indexrrXYqk3dLfMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/index.vue").then(m => m.default || m)
  },
  {
    name: technikulovzHZERTTMeta?.name ?? undefined,
    path: technikulovzHZERTTMeta?.path ?? "techniku",
    meta: technikulovzHZERTTMeta || {},
    alias: technikulovzHZERTTMeta?.alias || [],
    redirect: technikulovzHZERTTMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/techniku.vue").then(m => m.default || m),
    children: [
  {
    name: index6BNEOFomwVMeta?.name ?? "servis-absence-techniku___cs",
    path: index6BNEOFomwVMeta?.path ?? "",
    meta: index6BNEOFomwVMeta || {},
    alias: index6BNEOFomwVMeta?.alias || [],
    redirect: index6BNEOFomwVMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/techniku/index.vue").then(m => m.default || m)
  },
  {
    name: prehledWbHmWhHBHbMeta?.name ?? "servis-absence-techniku-prehled___cs",
    path: prehledWbHmWhHBHbMeta?.path ?? "prehled",
    meta: prehledWbHmWhHBHbMeta || {},
    alias: prehledWbHmWhHBHbMeta?.alias || [],
    redirect: prehledWbHmWhHBHbMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/techniku/prehled.vue").then(m => m.default || m)
  },
  {
    name: seznam0QG2ZiRayhMeta?.name ?? "servis-absence-techniku-seznam___cs",
    path: seznam0QG2ZiRayhMeta?.path ?? "seznam",
    meta: seznam0QG2ZiRayhMeta || {},
    alias: seznam0QG2ZiRayhMeta?.alias || [],
    redirect: seznam0QG2ZiRayhMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/techniku/seznam.vue").then(m => m.default || m)
  }
]
  },
  {
    name: vozidelD4a8HAm5D5Meta?.name ?? undefined,
    path: vozidelD4a8HAm5D5Meta?.path ?? "vozidel",
    meta: vozidelD4a8HAm5D5Meta || {},
    alias: vozidelD4a8HAm5D5Meta?.alias || [],
    redirect: vozidelD4a8HAm5D5Meta?.redirect,
    component: () => import("/app/src/pages/servis/absence/vozidel.vue").then(m => m.default || m),
    children: [
  {
    name: indexWVHrPi9BOrMeta?.name ?? "servis-absence-vozidel___cs",
    path: indexWVHrPi9BOrMeta?.path ?? "",
    meta: indexWVHrPi9BOrMeta || {},
    alias: indexWVHrPi9BOrMeta?.alias || [],
    redirect: indexWVHrPi9BOrMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/vozidel/index.vue").then(m => m.default || m)
  },
  {
    name: prehledRkWTHeo3OwMeta?.name ?? "servis-absence-vozidel-prehled___cs",
    path: prehledRkWTHeo3OwMeta?.path ?? "prehled",
    meta: prehledRkWTHeo3OwMeta || {},
    alias: prehledRkWTHeo3OwMeta?.alias || [],
    redirect: prehledRkWTHeo3OwMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/vozidel/prehled.vue").then(m => m.default || m)
  },
  {
    name: seznam2Z6JVIwxJFMeta?.name ?? "servis-absence-vozidel-seznam___cs",
    path: seznam2Z6JVIwxJFMeta?.path ?? "seznam",
    meta: seznam2Z6JVIwxJFMeta || {},
    alias: seznam2Z6JVIwxJFMeta?.alias || [],
    redirect: seznam2Z6JVIwxJFMeta?.redirect,
    component: () => import("/app/src/pages/servis/absence/vozidel/seznam.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: akcegihWnybZKuMeta?.name ?? undefined,
    path: akcegihWnybZKuMeta?.path ?? "akce",
    meta: akcegihWnybZKuMeta || {},
    alias: akcegihWnybZKuMeta?.alias || [],
    redirect: akcegihWnybZKuMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce.vue").then(m => m.default || m),
    children: [
  {
    name: aktivnierVoX67nFhMeta?.name ?? "servis-akce-aktivni___cs",
    path: aktivnierVoX67nFhMeta?.path ?? "aktivni",
    meta: aktivnierVoX67nFhMeta || {},
    alias: aktivnierVoX67nFhMeta?.alias || [],
    redirect: aktivnierVoX67nFhMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce/aktivni.vue").then(m => m.default || m)
  },
  {
    name: indexLW0WG2yb47Meta?.name ?? "servis-akce___cs",
    path: indexLW0WG2yb47Meta?.path ?? "",
    meta: indexLW0WG2yb47Meta || {},
    alias: indexLW0WG2yb47Meta?.alias || [],
    redirect: indexLW0WG2yb47Meta?.redirect,
    component: () => import("/app/src/pages/servis/akce/index.vue").then(m => m.default || m)
  },
  {
    name: neaktivnixIVCozhJX0Meta?.name ?? "servis-akce-neaktivni___cs",
    path: neaktivnixIVCozhJX0Meta?.path ?? "neaktivni",
    meta: neaktivnixIVCozhJX0Meta || {},
    alias: neaktivnixIVCozhJX0Meta?.alias || [],
    redirect: neaktivnixIVCozhJX0Meta?.redirect,
    component: () => import("/app/src/pages/servis/akce/neaktivni.vue").then(m => m.default || m)
  },
  {
    name: nenaplanovanepSTwqjMzstMeta?.name ?? "servis-akce-nenaplanovane___cs",
    path: nenaplanovanepSTwqjMzstMeta?.path ?? "nenaplanovane",
    meta: nenaplanovanepSTwqjMzstMeta || {},
    alias: nenaplanovanepSTwqjMzstMeta?.alias || [],
    redirect: nenaplanovanepSTwqjMzstMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce/nenaplanovane.vue").then(m => m.default || m)
  },
  {
    name: provedeneINMjoVFsxNMeta?.name ?? "servis-akce-provedene___cs",
    path: provedeneINMjoVFsxNMeta?.path ?? "provedene",
    meta: provedeneINMjoVFsxNMeta || {},
    alias: provedeneINMjoVFsxNMeta?.alias || [],
    redirect: provedeneINMjoVFsxNMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce/provedene.vue").then(m => m.default || m)
  },
  {
    name: rozplanovaneyyNPE1LvKhMeta?.name ?? "servis-akce-rozplanovane___cs",
    path: rozplanovaneyyNPE1LvKhMeta?.path ?? "rozplanovane",
    meta: rozplanovaneyyNPE1LvKhMeta || {},
    alias: rozplanovaneyyNPE1LvKhMeta?.alias || [],
    redirect: rozplanovaneyyNPE1LvKhMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce/rozplanovane.vue").then(m => m.default || m)
  },
  {
    name: vsechnyHLLfB23uGxMeta?.name ?? "servis-akce-vsechny___cs",
    path: vsechnyHLLfB23uGxMeta?.path ?? "vsechny",
    meta: vsechnyHLLfB23uGxMeta || {},
    alias: vsechnyHLLfB23uGxMeta?.alias || [],
    redirect: vsechnyHLLfB23uGxMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce/vsechny.vue").then(m => m.default || m)
  },
  {
    name: zrusene03cqaq5uuyMeta?.name ?? "servis-akce-zrusene___cs",
    path: zrusene03cqaq5uuyMeta?.path ?? "zrusene",
    meta: zrusene03cqaq5uuyMeta || {},
    alias: zrusene03cqaq5uuyMeta?.alias || [],
    redirect: zrusene03cqaq5uuyMeta?.redirect,
    component: () => import("/app/src/pages/servis/akce/zrusene.vue").then(m => m.default || m)
  }
]
  },
  {
    name: chladiva1NIwLTX8U9Meta?.name ?? undefined,
    path: chladiva1NIwLTX8U9Meta?.path ?? "chladiva",
    meta: chladiva1NIwLTX8U9Meta || {},
    alias: chladiva1NIwLTX8U9Meta?.alias || [],
    redirect: chladiva1NIwLTX8U9Meta?.redirect,
    component: () => import("/app/src/pages/servis/chladiva.vue").then(m => m.default || m),
    children: [
  {
    name: indexy1xTRMDP2cMeta?.name ?? "servis-chladiva___cs",
    path: indexy1xTRMDP2cMeta?.path ?? "",
    meta: indexy1xTRMDP2cMeta || {},
    alias: indexy1xTRMDP2cMeta?.alias || [],
    redirect: indexy1xTRMDP2cMeta?.redirect,
    component: () => import("/app/src/pages/servis/chladiva/index.vue").then(m => m.default || m)
  },
  {
    name: novaev0njr9hUjMeta?.name ?? "servis-chladiva-nova___cs",
    path: novaev0njr9hUjMeta?.path ?? "nova",
    meta: novaev0njr9hUjMeta || {},
    alias: novaev0njr9hUjMeta?.alias || [],
    redirect: novaev0njr9hUjMeta?.redirect,
    component: () => import("/app/src/pages/servis/chladiva/nova.vue").then(m => m.default || m)
  },
  {
    name: odsataixFkOQdEhoMeta?.name ?? "servis-chladiva-odsata___cs",
    path: odsataixFkOQdEhoMeta?.path ?? "odsata",
    meta: odsataixFkOQdEhoMeta || {},
    alias: odsataixFkOQdEhoMeta?.alias || [],
    redirect: odsataixFkOQdEhoMeta?.redirect,
    component: () => import("/app/src/pages/servis/chladiva/odsata.vue").then(m => m.default || m)
  },
  {
    name: stavjlv5HkRv06Meta?.name ?? "servis-chladiva-stav___cs",
    path: stavjlv5HkRv06Meta?.path ?? "stav",
    meta: stavjlv5HkRv06Meta || {},
    alias: stavjlv5HkRv06Meta?.alias || [],
    redirect: stavjlv5HkRv06Meta?.redirect,
    component: () => import("/app/src/pages/servis/chladiva/stav.vue").then(m => m.default || m)
  }
]
  },
  {
    name: ciselnikyCFtcrXpPQEMeta?.name ?? undefined,
    path: ciselnikyCFtcrXpPQEMeta?.path ?? "ciselniky",
    meta: ciselnikyCFtcrXpPQEMeta || {},
    alias: ciselnikyCFtcrXpPQEMeta?.alias || [],
    redirect: ciselnikyCFtcrXpPQEMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky.vue").then(m => m.default || m),
    children: [
  {
    name: indexxo9Yy89QBqMeta?.name ?? "servis-ciselniky___cs",
    path: indexxo9Yy89QBqMeta?.path ?? "",
    meta: indexxo9Yy89QBqMeta || {},
    alias: indexxo9Yy89QBqMeta?.alias || [],
    redirect: indexxo9Yy89QBqMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/index.vue").then(m => m.default || m)
  },
  {
    name: strediskaXnscYd4WhNMeta?.name ?? "servis-ciselniky-strediska___cs",
    path: strediskaXnscYd4WhNMeta?.path ?? "strediska",
    meta: strediskaXnscYd4WhNMeta || {},
    alias: strediskaXnscYd4WhNMeta?.alias || [],
    redirect: strediskaXnscYd4WhNMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/strediska.vue").then(m => m.default || m)
  },
  {
    name: techniciYUxeDNx95VMeta?.name ?? "servis-ciselniky-technici___cs",
    path: techniciYUxeDNx95VMeta?.path ?? "technici",
    meta: techniciYUxeDNx95VMeta || {},
    alias: techniciYUxeDNx95VMeta?.alias || [],
    redirect: techniciYUxeDNx95VMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/technici.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Apjgmo0swiMeta?.name ?? "servis-ciselniky-vozidla-id___cs",
    path: _91id_93Apjgmo0swiMeta?.path ?? "vozidla/:id()",
    meta: _91id_93Apjgmo0swiMeta || {},
    alias: _91id_93Apjgmo0swiMeta?.alias || [],
    redirect: _91id_93Apjgmo0swiMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/vozidla/[id].vue").then(m => m.default || m),
    children: [
  {
    name: absenceyCUgpFPtcrMeta?.name ?? "servis-ciselniky-vozidla-id-absence___cs",
    path: absenceyCUgpFPtcrMeta?.path ?? "absence",
    meta: absenceyCUgpFPtcrMeta || {},
    alias: absenceyCUgpFPtcrMeta?.alias || [],
    redirect: absenceyCUgpFPtcrMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/vozidla/[id]/absence.vue").then(m => m.default || m)
  },
  {
    name: vyjezdyXTuxGHCDYBMeta?.name ?? "servis-ciselniky-vozidla-id-vyjezdy___cs",
    path: vyjezdyXTuxGHCDYBMeta?.path ?? "vyjezdy",
    meta: vyjezdyXTuxGHCDYBMeta || {},
    alias: vyjezdyXTuxGHCDYBMeta?.alias || [],
    redirect: vyjezdyXTuxGHCDYBMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/vozidla/[id]/vyjezdy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFfbO4fn5lKMeta?.name ?? "servis-ciselniky-vozidla___cs",
    path: indexFfbO4fn5lKMeta?.path ?? "vozidla",
    meta: indexFfbO4fn5lKMeta || {},
    alias: indexFfbO4fn5lKMeta?.alias || [],
    redirect: indexFfbO4fn5lKMeta?.redirect,
    component: () => import("/app/src/pages/servis/ciselniky/vozidla/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: den0HX2HGJnMnMeta?.name ?? "servis-den___cs",
    path: den0HX2HGJnMnMeta?.path ?? "den",
    meta: den0HX2HGJnMnMeta || {},
    alias: den0HX2HGJnMnMeta?.alias || [],
    redirect: den0HX2HGJnMnMeta?.redirect,
    component: () => import("/app/src/pages/servis/den.vue").then(m => m.default || m)
  },
  {
    name: dokumentace7fmpPKJiyrMeta?.name ?? "servis-dokumentace___cs",
    path: dokumentace7fmpPKJiyrMeta?.path ?? "dokumentace",
    meta: dokumentace7fmpPKJiyrMeta || {},
    alias: dokumentace7fmpPKJiyrMeta?.alias || [],
    redirect: dokumentace7fmpPKJiyrMeta?.redirect,
    component: () => import("/app/src/pages/servis/dokumentace.vue").then(m => m.default || m)
  },
  {
    name: historieok7I5m8D9OMeta?.name ?? "servis-historie___cs",
    path: historieok7I5m8D9OMeta?.path ?? "historie",
    meta: historieok7I5m8D9OMeta || {},
    alias: historieok7I5m8D9OMeta?.alias || [],
    redirect: historieok7I5m8D9OMeta?.redirect,
    component: () => import("/app/src/pages/servis/historie.vue").then(m => m.default || m)
  },
  {
    name: indexYuS0KgKJm5Meta?.name ?? "servis___cs",
    path: indexYuS0KgKJm5Meta?.path ?? "",
    meta: indexYuS0KgKJm5Meta || {},
    alias: indexYuS0KgKJm5Meta?.alias || [],
    redirect: indexYuS0KgKJm5Meta?.redirect,
    component: () => import("/app/src/pages/servis/index.vue").then(m => m.default || m)
  },
  {
    name: mesicwCVm8JOhsbMeta?.name ?? "servis-mesic___cs",
    path: mesicwCVm8JOhsbMeta?.path ?? "mesic",
    meta: mesicwCVm8JOhsbMeta || {},
    alias: mesicwCVm8JOhsbMeta?.alias || [],
    redirect: mesicwCVm8JOhsbMeta?.redirect,
    component: () => import("/app/src/pages/servis/mesic.vue").then(m => m.default || m)
  },
  {
    name: prehledLhAais2StJMeta?.name ?? "servis-prehled___cs",
    path: prehledLhAais2StJMeta?.path ?? "prehled",
    meta: prehledLhAais2StJMeta || {},
    alias: prehledLhAais2StJMeta?.alias || [],
    redirect: prehledLhAais2StJMeta?.redirect,
    component: () => import("/app/src/pages/servis/prehled.vue").then(m => m.default || m)
  },
  {
    name: sluzbyovR34K4wMnMeta?.name ?? "servis-sluzby___cs",
    path: sluzbyovR34K4wMnMeta?.path ?? "sluzby",
    meta: sluzbyovR34K4wMnMeta || {},
    alias: sluzbyovR34K4wMnMeta?.alias || [],
    redirect: sluzbyovR34K4wMnMeta?.redirect,
    component: () => import("/app/src/pages/servis/sluzby.vue").then(m => m.default || m)
  },
  {
    name: techniciPxdVesBnRaMeta?.name ?? "servis-technici___cs",
    path: techniciPxdVesBnRaMeta?.path ?? "technici",
    meta: techniciPxdVesBnRaMeta || {},
    alias: techniciPxdVesBnRaMeta?.alias || [],
    redirect: techniciPxdVesBnRaMeta?.redirect,
    component: () => import("/app/src/pages/servis/technici.vue").then(m => m.default || m)
  },
  {
    name: tydenMm2eAqbOWPMeta?.name ?? "servis-tyden___cs",
    path: tydenMm2eAqbOWPMeta?.path ?? "tyden",
    meta: tydenMm2eAqbOWPMeta || {},
    alias: tydenMm2eAqbOWPMeta?.alias || [],
    redirect: tydenMm2eAqbOWPMeta?.redirect,
    component: () => import("/app/src/pages/servis/tyden.vue").then(m => m.default || m)
  },
  {
    name: vyjezdyuMF6Q4flaPMeta?.name ?? "servis-vyjezdy___cs",
    path: vyjezdyuMF6Q4flaPMeta?.path ?? "vyjezdy",
    meta: vyjezdyuMF6Q4flaPMeta || {},
    alias: vyjezdyuMF6Q4flaPMeta?.alias || [],
    redirect: vyjezdyuMF6Q4flaPMeta?.redirect,
    component: () => import("/app/src/pages/servis/vyjezdy.vue").then(m => m.default || m)
  },
  {
    name: vzkazyT51o7BQm5dMeta?.name ?? "servis-vzkazy___cs",
    path: vzkazyT51o7BQm5dMeta?.path ?? "vzkazy",
    meta: vzkazyT51o7BQm5dMeta || {},
    alias: vzkazyT51o7BQm5dMeta?.alias || [],
    redirect: vzkazyT51o7BQm5dMeta?.redirect,
    component: () => import("/app/src/pages/servis/vzkazy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sluzbaXGQvFrgoycMeta?.name ?? undefined,
    path: sluzbaXGQvFrgoycMeta?.path ?? "/sluzba",
    meta: sluzbaXGQvFrgoycMeta || {},
    alias: sluzbaXGQvFrgoycMeta?.alias || [],
    redirect: sluzbaXGQvFrgoycMeta?.redirect,
    component: () => import("/app/src/pages/sluzba.vue").then(m => m.default || m),
    children: [
  {
    name: index6A3oH271ZgMeta?.name ?? "sluzba-dokumentace___cs",
    path: index6A3oH271ZgMeta?.path ?? "dokumentace",
    meta: index6A3oH271ZgMeta || {},
    alias: index6A3oH271ZgMeta?.alias || [],
    redirect: index6A3oH271ZgMeta?.redirect,
    component: () => import("/app/src/pages/sluzba/dokumentace/index.vue").then(m => m.default || m)
  },
  {
    name: indexUGv5V8VltuMeta?.name ?? "sluzba-hlavni-strana___cs",
    path: indexUGv5V8VltuMeta?.path ?? "hlavni-strana",
    meta: indexUGv5V8VltuMeta || {},
    alias: indexUGv5V8VltuMeta?.alias || [],
    redirect: indexUGv5V8VltuMeta?.redirect,
    component: () => import("/app/src/pages/sluzba/hlavni-strana/index.vue").then(m => m.default || m)
  },
  {
    name: indexImQhStrvbQMeta?.name ?? "sluzba___cs",
    path: indexImQhStrvbQMeta?.path ?? "",
    meta: indexImQhStrvbQMeta || {},
    alias: indexImQhStrvbQMeta?.alias || [],
    redirect: indexImQhStrvbQMeta?.redirect,
    component: () => import("/app/src/pages/sluzba/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: spravaq1GFPICjvnMeta?.name ?? undefined,
    path: spravaq1GFPICjvnMeta?.path ?? "/sprava",
    meta: spravaq1GFPICjvnMeta || {},
    alias: spravaq1GFPICjvnMeta?.alias || [],
    redirect: spravaq1GFPICjvnMeta?.redirect,
    component: () => import("/app/src/pages/sprava.vue").then(m => m.default || m),
    children: [
  {
    name: indexlMJu7SBJj2Meta?.name ?? "sprava___cs",
    path: indexlMJu7SBJj2Meta?.path ?? "",
    meta: indexlMJu7SBJj2Meta || {},
    alias: indexlMJu7SBJj2Meta?.alias || [],
    redirect: indexlMJu7SBJj2Meta?.redirect,
    component: () => import("/app/src/pages/sprava/index.vue").then(m => m.default || m)
  }
]
  }
]