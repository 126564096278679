<template>
  <div class="text-center">
    <template v-if="error.statusCode === 404">
      <h1 class="mb-2 title mt-2">
        <b>Stránka nebyla nalezena</b>
      </h1>
    </template>
    <template v-if="error.statusCode === 500">
      <h1 class="mb-2 title mt-2">
        <b>Vyskytla se chyba</b>
      </h1>
    </template>
    <template v-if="error.statusCode === 502">
      <h1 class="mb-2 title mt-2">
        <b>Server je momentálně nedostupný</b>
      </h1>
    </template>
    <template v-if="error.statusCode === 403">
      <h1 class="mb-2 title mt-2">
        <b>Na tuto stránku nemáte oprávnění</b>
      </h1>
    </template>

    <div v-if="isDev && showBacktrace" style="text-align: center;" v-html="error.message" />
    <div v-if="isDev && showBacktrace" v-html="error.stack" />

    <span class="notice mb-3 m-auto text-center d-block">Prosím vraťte se na úvodní stranu.</span>
    <v-btn class="btn mb-5 btn--primary btn--icon arrow-left" :to="localePath({name: 'index'})">
      Zpět na úvod
    </v-btn>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: Record<string, unknown>
}>();

const localePath = useLocalePath();

const isDev = computed(() => import.meta.dev || true); // TODO: Remove true after deploy to production
const showBacktrace = computed(() => (props.error.statusCode as number) >= 500);

</script>
