import { isObject } from "lodash-es";

type NotificationType = "warn" | "success" | "error" | (string & {});

interface NotificationsOptions {
  id?: number;
  title?: string;
  text?: string;
  type?: NotificationType;
  group?: string;
  duration?: number;
  speed?: number;
  data?: unknown;
  clean?: boolean;
  clear?: boolean;
  ignoreDuplicates?: boolean;
  closeOnClick?: boolean;
}

type NotifierOptions = {
  rawMessage?: boolean,
  rawTitle?: boolean,
  notificationOptions?: NotificationsOptions
}

export default function useNotifier () {
  const { notify } = useNotification();
  const { t } = useNuxtApp().$i18n; // cannot use useI18n() outside of script setup

  function tryTranslateAsKey (base: string, key: string): string {
    const translation = t(`${base}.${key}`);
    return translation === `${base}.${key}` ? t(key) : translation;
  }

  function error (message?: string | null, title?: string | null, opts: NotifierOptions = {}) {
    notify({
      title: opts.rawTitle ? t(title ?? "common.error") : `${t("common.error")}${title ? `: ${t(title)}` : ""}`,
      text: message ? opts.rawMessage ? t(message) : tryTranslateAsKey("notifications.error", message) : t("notifications.error.default"),
      type: "error",
      ...opts.notificationOptions
    });
  }

  function warning (message?: string | null, title?: string | null, opts: NotifierOptions = {}) {
    notify({
      title: opts.rawTitle ? t(title ?? "common.warning") : `${t("common.warning")}${title ? `: ${t(title)}` : ""}`,
      text: message ? opts.rawMessage ? t(message) : tryTranslateAsKey("notifications.warning", message) : undefined,
      type: "warn",
      ...opts.notificationOptions
    });
  }

  function success (message?: string | null, title?: string | null, opts: NotifierOptions = {}) {
    notify({
      title: title ? opts.rawTitle ? t(title) : `${t("common.success")}: ${t(title)}` : undefined,
      text: message ? opts.rawMessage ? t(message) : tryTranslateAsKey("notifications.success", message) : t("notifications.success.default"),
      type: "success",
      ...opts.notificationOptions
    });
  }

  function parseError (errorObj: any, objKeyTranslator?: (key: string) => string): string {
    if (!errorObj) {
      return "";
    }
    if (Array.isArray(errorObj)) {
      errorObj = parseArray(errorObj);
    } else if (isObject(errorObj)) {
      errorObj = parseObject(errorObj, objKeyTranslator);
    }
    return errorObj.toString();
  }

  function parseObject (value: object, objKeyTranslator?: (key: string) => string) {
    let res = "<ul>";
    for (const [key, val] of Object.entries(value)) {
      const valStr = parseError(val);
      const k = objKeyTranslator ? objKeyTranslator(key) : key;
      res += `<li><strong>${k}:</strong> ${valStr}</li>`;
    }
    return res + "</ul>";
  }

  function parseArray (value: any[]) {
    let res = "<ul>";
    for (const val of value) {
      const valStr = parseError(val);
      res += `<li>${valStr}</li>`;
    }
    return res + "</ul>";
  }

  function errorObject (errorObj?: any, title?: string | null, opts: NotifierOptions = {}, objKeyTranslator?: (key: string) => string) {
    if (!error) {
      error(undefined, title, opts);
    }
    const message = parseError(errorObj, objKeyTranslator);
    error(message, title, opts);
  }

  return { error, warning, success, errorObject };
}
