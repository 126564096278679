import mitt from "mitt";
import type { Emitter } from "mitt";

export const eventTypes = {
  ALL_VIDEOS_PAUSE: "allVideosPause",
  VIDEO_WATCHED: "videoWatched"
} as const;

export type Events = {
  [eventTypes.ALL_VIDEOS_PAUSE]: void,
  [eventTypes.VIDEO_WATCHED]: { url: string, isWatched: boolean }
};

export default defineNuxtPlugin(() => {
  const emitter: Emitter<Events> = mitt<Events>();

  return {
    provide: {
      bus: {
        ...emitter,
        EVENT_TYPES: eventTypes
      }
    }
  };
});
