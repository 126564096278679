import type { PageMeta } from "nuxt/app";
export default function useDetailPageMeta (name: string) {
  const defaultDetailPageMeta: PageMeta = {
    layout: "detail",
    name,
    scrollToTop: (to, from) => {
      // Do not scroll to top of the page if within the same detail page
      if ("name" in from.meta) {
        return from.meta.name !== to.meta.name;
      }
      return true;
    }
  };

  return defaultDetailPageMeta;
}
