/* eslint-disable */
import * as Sentry from "@sentry/vue";
import type { Breadcrumb } from "@sentry/types/types/breadcrumb";
import type { Primitive } from "@sentry/types";
import type { User } from "@sentry/vue";
// import type { VueRouter } from "@intlify/vue-router-bridge";
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (config.public.environment === "develop" || config.public.environment === "development" || config.public.sentry_dsn === undefined) { return; }

  const { vueApp } = nuxtApp;
  Sentry.init({
    app: [vueApp],
    release: config.public.release as string,
    dsn: config.public.sentry_dsn as string,
    integrations: [
      Sentry.browserTracingIntegration({
        // @ts-ignore
        router: nuxtApp.$router
      }),
      Sentry.replayIntegration()
    ],
    logErrors: false, // Note that this doesn't seem to work with nuxt 3
    tracesSampleRate: parseFloat(config.public.sentry_traces_sample_rate as string) || 1.0, // Sentry recommends adjusting this value in production
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    debug: (config.public.sentry_enable_debug || false) as boolean, // Enable debug mode
    environment: (config.public.environment || "development") as string, // Set environment
    trackComponents: false, // This needs to be set to false, otherwise it will cause error, https://github.com/nuxt-community/sentry-module/issues/358, https://github.com/getsentry/sentry-javascript/issues/7909, https://github.com/getsentry/sentry-javascript/issues/8143
    // The following enables exceptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {

        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint });
      }
      // Continue sending to Sentry
      return event;
    }
  });

  // vueApp.mixin(Sentry.createTracingMixins({ trackComponents: false, timeout: 2000, hooks: ["activate", "mount", "update"] }));
  // Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: false, timeout: 2000, hooks: ["activate", "mount", "update"] });

  return {
    provide: {
      sentrySetContext: (n: string, context: {[p: string]: never} | null) => Sentry.setContext(n, context),
      sentrySetUser: (user: User | null) => Sentry.setUser(user),
      sentrySetTag: (tagName: string, value: Primitive) => Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb: Breadcrumb) => Sentry.addBreadcrumb(breadcrumb)
    }
  };
});
