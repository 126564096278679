<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import "@olc/vue-loader/dist/style.css";
import "@vuepic/vue-datepicker/dist/main.css";

useHead({
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" }
  ]
});
</script>

<style itemscope>
i.v-icon svg.icon + svg.icon {
  display: none;
}
</style>
