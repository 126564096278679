// import { h } from "vue";
import { defineAsyncComponent, h } from "vue";
import type { IconProps, IconSet } from "vuetify";

export const iconSet: IconSet = {
  component: (props: IconProps) => h(
    props.tag,
    {},
    [h(defineAsyncComponent(() => import(`./assets/svg/${props.icon}.svg`)), { class: `icon icon--${props.icon}` })]
  )
};
